import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Dialog, DialogProps, IconButton, styled } from '@mui/material';
import clsx from 'clsx';

import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import { colors } from '~/theme/colors';

import styles from './styles.module.scss';

type PositionType = {
  horizontal: 'left' | 'center' | 'right';
  vertical: 'top' | 'center' | 'bottom';
};

interface ModalProps extends DialogProps {
  pageViewMobile: boolean;
  width?: string;
  position: PositionType;
  isDesktop?: boolean;
  paperStyles?: React.CSSProperties;
}

const getModalPosition = (
  position: PositionType,
): { justifyContent: string; alignItems: string } => {
  const justifyContentMap: Record<PositionType['horizontal'], string> = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  };

  const alignItemsMap: Record<PositionType['vertical'], string> = {
    top: 'flex-start',
    center: 'center',
    bottom: 'flex-end',
  };

  return {
    justifyContent: justifyContentMap[position.horizontal],
    alignItems: alignItemsMap[position.vertical],
  };
};

const StyledModal = styled(Dialog)<ModalProps>(
  ({ theme, pageViewMobile, width, position, isDesktop, paperStyles }) => ({
    '& .MuiDialog-container': {
      height: '100%',
      position: 'relative',
      ...getModalPosition(position),
    },
    '& .MuiPaper-root': {
      position: 'relative',
      maxWidth: width ? `${width} !important` : '432px',
      width: '100%',
      borderRadius: '24px',
      margin: isDesktop ? '60px' : '20px',
      background: '#FBF8FD',

      [theme.breakpoints.down('sm')]: pageViewMobile && {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        margin: 0,
        height: 'auto',
        minHeight: '40%',
        maxHeight: '100%',
        marginTop: 'auto',
        borderRadius: '16px 16px 0 0',
      },
      ...paperStyles,
    },
    '& .MuiBackdrop-root': {
      backdropFilter: 'blur(6px)',
      backgroundColor: 'rgba(43, 49, 67, 0.1)',
    },
  }),
);

export interface ModalTypeProps {
  isOpen: boolean;
  onClose(): void;
  closeButtonColor?: string;
  hideCloseButton?: boolean;
  pageViewMobile?: boolean;
  isFullScreen?: boolean;
  disableBackdropClick?: boolean;
  paddingSize?: string;
  children?: React.ReactNode;
  maxWidth?: string;
  position?: PositionType;
  hideBackdrop?: boolean;
  paperStyles?: React.CSSProperties;
}

const Modal: FC<ModalTypeProps> = ({
  isOpen,
  closeButtonColor = colors.black,
  hideCloseButton = false,
  pageViewMobile = true,
  children,
  onClose,
  isFullScreen,
  disableBackdropClick = false,
  paddingSize = '24px',
  maxWidth,
  position = { horizontal: 'center', vertical: 'center' },
  hideBackdrop,
  paperStyles,
}) => {
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);
  return (
    <StyledModal
      open={isOpen}
      {...(!disableBackdropClick && { onClose })}
      fullScreen={isFullScreen}
      pageViewMobile={pageViewMobile}
      width={maxWidth}
      position={position}
      hideBackdrop={hideBackdrop}
      isDesktop={isDesktop}
      paperStyles={paperStyles}
    >
      <div
        className={clsx(styles.wrapper, {
          [styles.is_full]: isFullScreen,
        })}
      >
        <div className={styles.close_icon}>
          {!hideCloseButton && (
            <IconButton onClick={onClose} size="small">
              <Icon
                icon="material-symbols:close"
                width="24"
                color={closeButtonColor}
              />
            </IconButton>
          )}
        </div>
        <div className={styles.content} style={{ padding: paddingSize }}>
          {children}
        </div>
      </div>
    </StyledModal>
  );
};

export default Modal;
