export enum PRODUCT {
  ONETIME_DETECTORS_10_DOLLARS_10K_WORDS = '7649b99f-ac67-4203-814a-245ba3d61a2d',
  ONETIME_DETECTORS_20_DOLLARS_20K_WORDS = 'dd3cde1a-1193-43de-a666-73eb3d06597d',
  ONETIME_DETECTORS_40_DOLLARS_50K_WORDS = '52f2c29e-db41-4c4a-b1f2-e6ced91487c3',
  ONETIME_DETECTORS_80_DOLLARS_100K_WORDS = 'db7b5e57-1ea6-46f0-b0bf-efa7613405c9',
  ONETIME_DETECTORS_100_DOLLARS_200K_WORDS = 'd6c2ec10-f1e0-489d-8bf6-3b90f7949194',
  ANNUAL_SUB_OFFER = 'c28311f2-b8fb-4cca-8f21-cba1409d6b70',
  UNBLOCK_DETECTOR_TOOLS_20_DOLLARS = '7c70a459-57cc-42ad-b2f2-bc6aa677c1c4',
  UNBLOCK_DETECTOR_TOOLS_10_DOLLARS = 'ec2d5585-a1e2-4956-b528-e70479300f7e',
  UNBLOCK_DETECTOR_TOOLS_7_5_DOLLARS = '62e651da-158e-4c25-840d-c3adc03cf1ff',
}

export const PRODUCT_IDS_FOR_DOWNSELL = [
  '93987a0a-b017-49d4-8133-ee5d5598c034', // Justdone_plan_240_usd_365days_0.99_trial
  'f8dae528-66ab-4554-91c2-e8b6c4d0cc56', // Justdone_plan_240_usd_365days_0.99_trial_ex.det
  'ec0ff8b6-5e77-4916-bb36-9a7471d31d22', // Justdone_plan_49.99_usd_30days_0.99_trial
  '1f5a272c-97ec-49be-8c33-baf6cd5b7403', // Justdone_plan_49.99_usd_30days_0.99_trial_ex.det
  'c28311f2-b8fb-4cca-8f21-cba1409d6b70', // Justdone_plan_299_usd_365days
  'dbc97232-67cb-4ba2-963d-773b843a64f6', // Justdone_plan_299_usd_365days_ex.det
  'f3a52040-48c2-4943-8964-d8445cf7dcf2', // Justdone_plan_39.99_usd_30days
  '11a8e694-0532-494d-bda5-b7dcd4d6f1fd', // Justdone_plan_39.99_usd_30days_ex.det
  '31892563-b1b8-43e7-9f40-bf325330c954', // Justdone_plan_49.99_usd_30days_0.99_trial_rs9
];

export enum PRODUCT_TAG {
  FREEMIUM_DETECTOR_LIMIT = 'freemium_detectors_limit',
  EXCLUDE_DETECTORS = 'exclude_detectors',
}
