import React, { FC } from 'react';

import { Navigate } from 'react-router-dom';

import router from '~/core/router';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { FeaturesFields } from '~/types/profile';

interface Props {
  Component: FC;
  checkAvailability?: (features: FeaturesFields) => boolean;
}

const UnAuthorizedRoute: FC<Props> = ({ Component, checkAvailability }) => {
  const { user_features } = useAppSelector((state) => state.profile);

  return (
    <>
      {checkAvailability ? (
        checkAvailability(user_features) ? (
          <Component />
        ) : (
          <Navigate to={router.getDefaultPrivateRoutePath()} />
        )
      ) : (
        <Component />
      )}
    </>
  );
};

export default UnAuthorizedRoute;
