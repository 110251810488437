import React, { FC, ReactElement } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';

import { Typographies } from '~/theme/typography';

type Props = {
  text: string | ReactElement;
  className?: string;
  variant?: Typographies;
  sx?: Record<string, any>;
};

const ModalContentTitle: FC<Props> = ({ className, text, ...props }) => {
  return (
    <Typography
      variant={props.variant ? props.variant : Typographies.TITLE_LARGE}
      sx={{ mb: '32px', fontWeight: '500' }}
      component="h4"
      className={clsx(className)}
      {...props}
    >
      {text}
    </Typography>
  );
};

export default ModalContentTitle;
