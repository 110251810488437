import AbstractStorage from './AbstractStorage';

class LocalStorage extends AbstractStorage {
  setByKey(key: string, value: string | null): void {
    if (!value) return;
    window.localStorage.setItem(key, value);
  }

  getByKey(key: string): string | null {
    return window.localStorage.getItem(key) || null;
  }

  removeByKey(key: string): void {
    window.localStorage.removeItem(key);
  }
}

const localStorage = new LocalStorage();

export default localStorage;
