import React, { FC } from 'react';

import { MenuItem, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import { PERSONALITY } from '~/modules/chat/types';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';
import { snakeCaseToCapitalizeCase } from '~/utils/text';

import styles from './styles.module.scss';

export const personalityEmojis = {
  [PERSONALITY.GENERAL]: '',
  [PERSONALITY.CAREER_COUNSELOR]: '🎯',
  [PERSONALITY.MARKETING_DIRECTOR]: '📈',
  [PERSONALITY.MOTIVATIONAL_COACH]: '💪',
  [PERSONALITY.BUSINESS_ANALYST]: '🔍',
  [PERSONALITY.SALES_PROFESSIONAL]: '💼',
  [PERSONALITY.COPYWRITING_MASTER]: '✍',
};

function createOptionsFromEnum(
  enumObj: typeof PERSONALITY,
): { value: string; text: string }[] {
  return Object.values(enumObj).map((value) => ({
    text: snakeCaseToCapitalizeCase(value),
    value,
  }));
}

type Props = {
  personality: PERSONALITY;
  setPersonality: (type: PERSONALITY) => void;
  onOpen: () => void;
  isDisabled?: boolean;
  className?: string;
};

const StyledTextField = styled(TextField)(() => ({
  '& .MuiSelect-select': {
    padding: '8px 36px 8px 5px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
  },
  '& .MuiSvgIcon-root': {
    color: colors.black,
  },
  '& input': {
    border: '0',
  },
  '& fieldset': {
    border: 'none',
  },
  '& .Mui-disabled': {
    '& .MuiSvgIcon-root': {
      opacity: '0.4',
    },
  },
}));

const Personality: FC<Props> = ({
  personality,
  setPersonality,
  onOpen,
  isDisabled,
  className,
}) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      <Typography
        component="p"
        variant={Typographies.LABEL_LARGE}
        className={styles.description}
      >
        AI Assistant:
      </Typography>

      <div className={styles.select_wrapper}>
        <Typography component="p" variant={Typographies.LABEL_LARGE}>
          {personalityEmojis[personality]}
        </Typography>

        <StyledTextField
          select
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  marginLeft: 0,
                  borderRadius: '4px',
                  backgroundColor: colors.neutral_80,
                },
                sx: {
                  '&::-webkit-scrollbar': {
                    width: '2px',
                  },

                  '&::-webkit-scrollbar-thumb': {
                    background: colors.primary_40,
                    'border-radius': '4px',
                  },
                },
              },
            },
            onOpen: onOpen,
          }}
          disabled={isDisabled}
          value={personality}
          onChange={(e): void => {
            setPersonality(e.target.value as PERSONALITY);
          }}
        >
          {createOptionsFromEnum(PERSONALITY).map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </StyledTextField>
      </div>
    </div>
  );
};

export default Personality;
