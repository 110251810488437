import { useMediaQuery } from 'react-responsive';

export enum Viewports {
  MOBILE = 0,
  TABLET = 768,
  EXTRA_SMALL_DESKTOP = 1024,
  SMALL_DESKTOP = 1280,
  MEDIUM_DESKTOP = 1440,
  BIG_DESKTOP = 1920,
}

export const useIsViewport = (viewport: Viewports): boolean => {
  switch (viewport) {
    case Viewports.MOBILE:
      return useMediaQuery({
        maxWidth: Viewports.TABLET,
      });

    default:
      return useMediaQuery({
        minWidth: viewport,
      });
  }
};
