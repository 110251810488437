import React, { ComponentType, ReactElement } from 'react';

import { Controller } from 'react-hook-form';

export const withHookForm =
  <T extends Record<string, any>>(
    WrappedComponent: ComponentType<Omit<T, 'onChange' | 'errorMessage'>>,
  ) =>
  ({ ...props }: T): ReactElement => {
    const { name } = props;

    return (
      <Controller
        name={name}
        render={({
          field: { onChange, value },
          fieldState: { error },
        }): ReactElement => (
          <WrappedComponent
            value={value}
            onChange={onChange}
            {...props}
            error={!!error?.message}
            {...(error?.message
              ? {
                  helperText: error.message,
                }
              : {})}
          />
        )}
      />
    );
  };
