import React, { createElement, lazy, LazyExoticComponent } from 'react';

import errorLogger from '~/services/ErrorLogger';

type DynamicImportType<T> = () => Promise<{ default: React.ComponentType<T> }>;
type LazyComponentType<T> = LazyExoticComponent<React.ComponentType<T>>;

const lazyComponentLoader = <T>(
  component: DynamicImportType<T>,
): LazyComponentType<T> => {
  return lazy(async () => {
    try {
      const module = await component();
      return { default: module.default };
    } catch (error: any) {
      console.error(error);
      errorLogger.captureException(error, 'Error in lazyComponentLoader');
      // window.location.reload(); // maybe temporary. observing sentry logs
      return {
        default: () => createElement('div', null),
      };
    }
  });
};

export default lazyComponentLoader;
