import {
  LoadingButton as MuiLoadingButton,
  LoadingButtonProps as MuiLoadingButtonProps,
} from '@mui/lab';
import { styled } from '@mui/material';

import {
  baseButtonStyles,
  ButtonShape,
} from '~/components/atoms/buttons/Button';
import { colors } from '~/theme/colors';

export type LoadingButtonProps = MuiLoadingButtonProps;

const LoadingButton = styled(MuiLoadingButton)<
  LoadingButtonProps & { shape?: ButtonShape }
>(({ shape = ButtonShape.PILL }) => ({
  ...baseButtonStyles(shape),

  '&.MuiLoadingButton-loading': {
    color: 'transparent !important',
  },

  '&.MuiButton-containedGradient': {
    color: colors.white,
    background: colors.gradient_40,

    '@media (hover: hover)': {
      '&:hover': {
        boxShadow:
          '0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30)',
      },
    },

    '.MuiLoadingButton-loadingIndicator': {
      color: colors.white,
    },

    '&:disabled': {
      background: colors.gradient_40,
    },
  },
}));

export default LoadingButton;
