import React, { FC } from 'react';

import { ProductType } from '~/types/product';

import StatsBenefitsVariant from './variants/StatsBenefitsVariant';

type Props = {
  onViewPlansClick?: () => void;
  onSubmit: () => Promise<void>;
  restoredProduct: ProductType;
};

const RestoreSub: FC<Props> = ({
  onViewPlansClick,
  onSubmit,
  restoredProduct,
}) => {
  return (
    <StatsBenefitsVariant
      product={restoredProduct}
      onSubmit={onSubmit}
      onViewPlansClick={onViewPlansClick}
    />
  );
};

export default RestoreSub;
