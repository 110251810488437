import React, { FC } from 'react';

import { Icon } from '@iconify/react';

import Button from '~/components/atoms/buttons/Button';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import { colors } from '~/theme/colors';

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
};
const DeleteAllDataModal: FC<Props> = ({ onCancel, onSubmit }) => {
  return (
    <div>
      <ModalContentTitle text="Delete all data" />
      <div
        style={{
          display: 'flex',
          marginBottom: '32px',
          gap: '15px',
        }}
      >
        <Icon
          style={{ fontSize: 53, color: colors.error_40, flexShrink: 0 }}
          icon="material-symbols:warning-rounded"
        />
        <ModalContentText>
          Data removal of your account will result in complete losing of access
          to account and history of your work.
          <b>This action cannot be reverted.</b>
        </ModalContentText>
      </div>

      <div>
        <Button onClick={onCancel} variant="contained">
          Cancel
        </Button>
        <Button onClick={onSubmit} variant="text">
          I wish to proceed
        </Button>
      </div>
    </div>
  );
};

export default DeleteAllDataModal;
