import React, { FC } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { OnboardingPath } from '~/modules/onboarding-new/constants';
import { useAppSelector } from '~/store/hooks/useAppSelector';

type Props = {
  children?: React.ReactNode;
};

const OnboardingMiddleware: FC<Props> = ({ children }) => {
  const { is_onboarded } = useAppSelector(
    (state) => state.profile,
    (state1, state2) => state1.is_onboarded !== state2.is_onboarded,
  );
  const location = useLocation();

  const redirectPath =
    location.state && location.state.from ? location.state.from : null;

  return is_onboarded ? (
    <>{children}</>
  ) : (
    <Navigate to={OnboardingPath} state={{ from: redirectPath }} />
  );
};

export default OnboardingMiddleware;
