import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import AndroidChromeAddressImg from '~/features/pwa/assets/v4_android_chrome_address_bar.webp';
import AndroidChromeAddressPreviewImg from '~/features/pwa/assets/v4_android_chrome_address_bar_preview.png';
import InstallAppImg from '~/features/pwa/assets/v4_android_install_app.webp';
import InstallAppPreviewImg from '~/features/pwa/assets/v4_android_install_app_preview.png';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  onSubmit: () => void;
};

const ChromeMobileAndroid: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation('modal');

  return (
    <div className={styles.container}>
      <div className={styles.steps}>
        <div className={styles.steps__item}>
          <Typography
            variant={Typographies.TITLE_MEDIUM}
            className={styles.steps__item_title}
            component="h4"
          >
            1.{' '}
            {t(
              'pwa_v4.chrome_mobile_android_steps[0]',
              'Tap this button on the top of Chrome',
            )}
          </Typography>

          <div>
            <Image
              src={AndroidChromeAddressImg}
              preview={AndroidChromeAddressPreviewImg}
            />
          </div>
        </div>

        <div className={styles.steps__item}>
          <Typography
            variant={Typographies.TITLE_MEDIUM}
            className={styles.steps__item_title}
            component="h4"
          >
            2.{' '}
            {t(
              'pwa_v4.chrome_mobile_android_steps[1]',
              'In window that appears tap this',
            )}
          </Typography>

          <div>
            <Image src={InstallAppImg} preview={InstallAppPreviewImg} />
          </div>
        </div>
      </div>

      <Button
        color="primary"
        variant="text"
        onClick={onSubmit}
        className={styles.submit_button}
      >
        <Icon
          icon="ic:round-done"
          style={{ color: colors.primary_40 }}
          width="18"
        />
        <span>{t('general:done', 'Done')}</span>
      </Button>
    </div>
  );
};

export default ChromeMobileAndroid;
