import React, { FC } from 'react';

import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import ViewAllPlansLink from '~/modules/settings/components/modals/ChooseSubscriptionModal/elements/ViewAllPlansLink';
import ProductCard from '~/modules/settings/components/ProductCard';
import analytics from '~/services/analytics';
import { ProductType } from '~/types/product';

import PauseImg from './pause.webp';
import PausePreviewImg from './pause_preview.png';
import styles from '../styles.module.scss';

type Props = {
  currentProduct: ProductType;
  onSubmit: () => Promise<void>;
  onViewPlansClick?: () => void;
};

const UnpauseSub: FC<Props> = ({
  currentProduct,
  onSubmit,
  onViewPlansClick,
}) => {
  const handleSubmit = async (): Promise<void> => {
    analytics.trackEvent('subscription - click paused sub screen');
    await onSubmit();
  };

  return (
    <div>
      <div className={styles.image}>
        <Image src={PauseImg} preview={PausePreviewImg} />
      </div>

      <ModalContentTitle text="Ready to Get Back?" className={styles.title} />

      <ModalContentText sx={{ mb: '24px', maxWidth: 'unset' }}>
        Your subscription is currently on pause. To continue enjoying our
        services, please renew your subscription.
      </ModalContentText>

      <div className={styles.benefits}>
        <ProductCard isSelectable product={currentProduct} isActive isAccent />
      </div>

      <Button
        variant="contained"
        color="gradient"
        size="large"
        fullWidth
        onClick={handleSubmit}
      >
        Renew Subscription
      </Button>

      {onViewPlansClick && <ViewAllPlansLink onClick={onViewPlansClick} />}
    </div>
  );
};

export default UnpauseSub;
