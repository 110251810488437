import React, { FC, useEffect } from 'react';

import { isEqual } from 'lodash';

import { useModalContext } from '~/contexts/modal/ModalContext';
import { getPWADeviceName } from '~/features/pwa/helpers';
import AddToHomeModalV1 from '~/features/pwa/versions/V1/AddToHomeModal';
import AddToHomeModalV4 from '~/features/pwa/versions/V4/AddToHomeModal';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import { AB_TEST_FEATURES, AB_TESTS_KEYS } from '~/services/abTests/features';
import { growthBook } from '~/services/abTests/growthBook';
import analytics from '~/services/analytics';
import DateService from '~/services/Date';
import sessionCounter from '~/services/sessionCounter';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { PWAModalExtraFieldsType } from '~/types/profile';

type Props = {
  children?: React.ReactNode;
};

export const SESSION_TO_TRIGGER_COUNT = 'session_to_trigger_count';

const PWAModalMiddleware: FC<Props> = ({ children }) => {
  const currentSessionCount = sessionCounter.getCurrentCount();
  const profile = useAppSelector(
    (state) => state.profile,
    (state1, state2) => !isEqual(state1.extra_fields, state2.extra_fields),
  );
  const { updateUserExtraFields } = useActions();
  const { handleOpenModal } = useModalContext();
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  const handleShowAddToHomeModal = (
    extra: Partial<PWAModalExtraFieldsType>,
    analytic_reason_property_value: string,
  ): void => {
    const isPWAV4 =
      growthBook.getAbTestValue(
        AB_TEST_FEATURES[AB_TESTS_KEYS.PWA_COMMUNICATION_V4_1].key,
      ) ===
      AB_TEST_FEATURES[AB_TESTS_KEYS.PWA_COMMUNICATION_V4_1].variants.variant_1;

    const deviceName = getPWADeviceName();

    if (deviceName === null) {
      return;
    }

    const eventProperties = {
      reason: analytic_reason_property_value,
      version: isPWAV4 ? 'v4' : 'v1',
    };

    handleOpenModal({
      onClose: () =>
        analytics.trackEvent('retention - close pwa modal', eventProperties),
      maxWidth: isDesktop && isPWAV4 ? 'max-content' : '460px',
      hideCloseButton: true,
      disableBackdropClick: true,
      paddingSize: isPWAV4 ? '0px' : '24px',
      component: ({ onClose }) =>
        isPWAV4 ? (
          <AddToHomeModalV4
            device={deviceName}
            onSubmit={(): void => {
              onClose();
              analytics.trackEvent(
                'retention - click pwa modal',
                eventProperties,
              );
            }}
            onDismiss={onClose}
          />
        ) : (
          <AddToHomeModalV1
            device={deviceName}
            onSubmit={(): void => {
              onClose();
              analytics.trackEvent(
                'retention - click pwa modal',
                eventProperties,
              );
            }}
          />
        ),
    });

    analytics.trackEvent('retention - pwa modal', eventProperties);

    updateUserExtraFields({
      pwa_modal: {
        ...profile.extra_fields.pwa_modal,
        last_showed_at: new Date().toISOString(),
        ...extra,
      },
    });
  };

  const checkAndShowModal = (): void => {
    if (
      profile.extra_fields.pwa_modal.last_showed_at &&
      Math.abs(
        DateService.differenceInDays(
          new Date(profile.extra_fields.pwa_modal.last_showed_at),
          new Date(),
        ),
      ) < 1
    ) {
      return;
    }

    const session_to_trigger_count = localStorage.getItem(
      SESSION_TO_TRIGGER_COUNT,
    );

    if (
      !profile.extra_fields.pwa_modal.is_showed_first_generation &&
      session_to_trigger_count &&
      currentSessionCount > Number(session_to_trigger_count)
    ) {
      handleShowAddToHomeModal(
        {
          is_showed_first_generation: true,
        },
        'next_visit_after_1st_gen',
      );
      return;
    }

    if (
      !profile.extra_fields.pwa_modal.is_showed_fifth_session &&
      currentSessionCount >= 5
    ) {
      handleShowAddToHomeModal(
        {
          is_showed_fifth_session: true,
        },
        '5th_visit',
      );
      return;
    }
  };

  useEffect(() => {
    checkAndShowModal();
  }, [profile]);

  return <>{children}</>;
};

export default PWAModalMiddleware;
