import { PWADevice } from '~/features/pwa/constants';
import { getDeviceName } from '~/utils/userAgentInfo';

export const getPWADeviceName = (): PWADevice | null => {
  const device = getDeviceName();
  // @ts-ignore
  if (!device || !Object.values(PWADevice).includes(device)) {
    return null;
  }

  return device as unknown as PWADevice;
};
