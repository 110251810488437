import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  styled,
} from '@mui/material';

export type Props = Omit<MuiButtonProps, 'startIcon' | 'endIcon'>;
import { colors } from '~/theme/colors';

const IconButton = styled(MuiButton, {
  name: 'StyledIconButton',
})(() => ({
  '&': {
    boxShadow: 'none',
    transition: '350ms ease-in-out',
    borderRadius: '10em',
    minWidth: '0px',
    padding: 0,

    '&:hover': {
      boxShadow: 'none',
    },
  },

  '&.MuiButton-containedPrimary': {
    backgroundColor: colors.primary_40,
    color: colors.white,

    '&:hover': {
      backgroundColor: colors.primary_50,
    },
  },

  '&.MuiButton-containedSecondary': {
    color: colors.white,
    backgroundColor: colors.black,

    '&:hover': {
      backgroundColor: colors.neutral_20,
    },
  },

  '&.MuiButton-outlined': {
    border: `1px solid ${colors.neutral_40}`,
    color: colors.black,

    '&:hover': {
      backgroundColor: colors.neutral_60,
    },
  },

  '&.MuiButton-outlinedNeutral': {
    border: `1px solid ${colors.neutral_30}`,

    '&:hover': {
      backgroundColor: colors.neutral_80,
    },

    '&:disabled': {
      opacity: '0.4',
    },
  },

  '&.MuiButton-outlinedSecondary': {
    border: `1px solid ${colors.primary_50}`,

    '&:hover': {
      backgroundColor: '#EEF0FF',
    },

    '&:disabled': {
      border: `1px solid #F0F0FA`,
      backgroundColor: '#F0F0FA',
    },
  },

  '&.MuiButton-sizeDoubleExtraSmall': {
    fontSize: '14px',
    width: '24px',
    height: '24px',
  },

  '&.MuiButton-sizeExtraSmall': {
    fontSize: '16px',
    width: '32px',
    height: '32px',
  },

  '&.MuiButton-sizeSmall': {
    fontSize: '18px',
    width: '40px',
    height: '40px',
  },

  '&.MuiButton-sizeMedium': {
    fontSize: '22px',
    width: '48px',
    height: '48px',
  },

  '&.MuiButton-sizeLarge': {
    fontSize: '26px',
    width: '56px',
    height: '56px',
  },

  '&.Mui-disabled': {
    color: colors.neutral_30,
  },
}));

export default IconButton;
