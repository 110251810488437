export const PersonalInformationPath = '/settings/personal-information';

export const PersonalInformationTitle = 'Personal Information';

export const SecurityPath = '/settings/security';

export const SecurityTitle = 'Password';

export const SubscriptionPath = '/settings/subscription';
export const SubscriptionTitle = 'Subscription';
