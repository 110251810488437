import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { SubscriptionPath } from '~/modules/settings/constants';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  onClick: () => void;
};

const ViewAllPlansLink: FC<Props> = ({ onClick }) => (
  <Typography
    className={styles.container}
    variant={Typographies.BODY_MEDIUM}
    component="p"
  >
    Need more?
    <br />
    <a href={SubscriptionPath} className={styles.link} onClick={onClick}>
      View all plans 🚀
    </a>
  </Typography>
);

export default ViewAllPlansLink;
