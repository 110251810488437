import React, { FC, useEffect } from 'react';

import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';

import IconButton from '~/components/atoms/buttons/IconButton';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import { HomePagePath } from '~/modules/home/constants';
import analytics from '~/services/analytics';

import styles from './styles.module.scss';

const MOBILE_CLOSE_TIMEOUT = 5000; //default 5000

type Props = {
  onClickClose: () => void;
};
const RetentionPopover: FC<Props> = ({ onClickClose }) => {
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);
  const navigate = useNavigate();

  useEffect(() => {
    analytics.trackEvent('notification - personalized toolkit');

    if (isDesktop) return;

    window.setTimeout(() => {
      onClickClose();
    }, MOBILE_CLOSE_TIMEOUT);
  }, []);

  return (
    <div
      className={styles.popper}
      onClick={(): void => {
        navigate(HomePagePath);
        onClickClose();
      }}
    >
      <div className={styles.popper__top}>
        <Icon
          icon="material-symbols:check-circle"
          className={styles.popper__check_icon}
        />
        <span className={styles.popper__text}>
          Your personalized AI toolkit is ready!
        </span>
        {isDesktop && (
          <IconButton
            className={styles.popper__close_button}
            onClick={(e): void => {
              e.stopPropagation();
              onClickClose();
            }}
          >
            <Icon
              className={styles.popper__close_icon}
              icon="material-symbols:close"
            />
          </IconButton>
        )}
      </div>
      {!isDesktop && (
        <div className={styles.popper__progress}>
          <div
            style={{
              animationDuration: `${MOBILE_CLOSE_TIMEOUT}ms`,
            }}
            className={styles.popper__progress__inner}
          />
        </div>
      )}
    </div>
  );
};

export default RetentionPopover;
