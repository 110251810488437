import TextField, {
  TextFieldCustomProps,
} from '~/components/atoms/inputs/TextField';
import { withHookForm } from '~/decorators/withHookForm';

export type HookTextFieldCustomProps = TextFieldCustomProps;

const HookFormTextField = withHookForm<TextFieldCustomProps>(TextField);

export default HookFormTextField;
