export enum Device {
  Ios_Safari = 'ios_safari',
  Ios_Chrome = 'ios_chrome',
  Android_Chrome = 'android_chrome',
  Mac_Chrome = 'mac_chrome',
  Mac_Safari = 'mac_safari',
  Mac_Edge = 'mac_edge',
  Mac_Firefox = 'mac_firefox',
  Windows_Chrome = 'windows_chrome',
  Windows_Edge = 'windows_edge',
  Windows_Firefox = 'windows_firefox',
  Linux_Chrome = 'linux_chrome',
  Linux_Firefox = 'linux_firefox',
  Unknown = 'unknown',
}

export enum Browser {
  Firefox = 'Firefox',
  Opera = 'Opera',
  Samsung_Browser = 'Samsung Browser',
  Edge = 'Edge',
  Chrome = 'Chrome',
  Safari = 'Safari',
  Unknown = 'Unknown browser',
}

export enum OS {
  Android = 'Android',
  IosIphone = 'iPhone',
  MacOs_IosIpad = 'iPad | iMac | Macbook',
  Linux = 'Linux',
  Windows = 'Windows',
  Unknown = 'Unknown OS',
}

const browserList: { name: Browser; value: RegExp }[] = [
  { name: Browser.Firefox, value: /\b(firefox|fxios)\b/i },
  { name: Browser.Opera, value: /\bopr\b/i },
  { name: Browser.Samsung_Browser, value: /\bsamsungbrowser\b/i }, // Specific rule for Samsung Browser
  { name: Browser.Edge, value: /edg/i },
  { name: Browser.Chrome, value: /\b(chrome|chromium|crios)\b/i },
  { name: Browser.Safari, value: /safari/i },
];

const OSList: { name: OS; value: string }[] = [
  { name: OS.Android, value: 'Android' },
  { name: OS.IosIphone, value: 'iPhone' },
  { name: OS.MacOs_IosIpad, value: 'Mac' },
  { name: OS.Linux, value: 'Linux' },
  { name: OS.Windows, value: 'Win' },
];

export const getUserBrowser = (): Browser => {
  const matchedBrowser = browserList.find((browser) =>
    navigator.userAgent.match(browser.value),
  );
  return matchedBrowser?.name || Browser.Unknown;
};

const getUserOS = (): OS => {
  const matchedOS = OSList.find((os) => navigator.userAgent.includes(os.value));
  return matchedOS?.name || OS.Unknown;
};

export const getUserAgentInfo = (): {
  browser: Browser;
  os: OS;
} => {
  const browser = getUserBrowser();
  const os = getUserOS();

  return {
    browser,
    os,
  };
};

export const getDeviceName = (): Device => {
  const DevicesToShow: {
    name: Device;
    checkToShow: (data: { os: OS; browser: Browser }) => boolean;
  }[] = [
    {
      name: Device.Ios_Safari,
      checkToShow: ({ os, browser }) =>
        browser === Browser.Safari && os === OS.IosIphone,
    },
    {
      name: Device.Ios_Chrome,
      checkToShow: ({ os, browser }) =>
        browser === Browser.Chrome && os === OS.IosIphone,
    },
    {
      name: Device.Android_Chrome,
      checkToShow: ({ os, browser }) =>
        browser === Browser.Chrome && os === OS.Android,
    },
    {
      name: Device.Mac_Chrome,
      checkToShow: ({ os, browser }) =>
        browser === Browser.Chrome && os === OS.MacOs_IosIpad,
    },
    {
      name: Device.Windows_Chrome,
      checkToShow: ({ os, browser }) =>
        browser === Browser.Chrome && os === OS.Windows,
    },
    {
      name: Device.Windows_Edge,
      checkToShow: ({ os, browser }) =>
        browser === Browser.Edge && os === OS.Windows,
    },
    {
      name: Device.Linux_Chrome,
      checkToShow: ({ os, browser }) =>
        browser === Browser.Chrome && os === OS.Linux,
    },
    {
      name: Device.Linux_Firefox,
      checkToShow: ({ os, browser }) =>
        browser === Browser.Firefox && os === OS.Linux,
    },
    {
      name: Device.Mac_Safari,
      checkToShow: ({ os, browser }) =>
        browser === Browser.Safari && os === OS.MacOs_IosIpad,
    },
    {
      name: Device.Mac_Edge,
      checkToShow: ({ os, browser }) =>
        browser === Browser.Edge && os === OS.MacOs_IosIpad,
    },
    {
      name: Device.Mac_Firefox,
      checkToShow: ({ os, browser }) =>
        browser === Browser.Firefox && os === OS.MacOs_IosIpad,
    },
    {
      name: Device.Windows_Edge,
      checkToShow: ({ os, browser }) =>
        browser === Browser.Edge && os === OS.Windows,
    },
  ];

  const device = DevicesToShow.find(({ checkToShow }) =>
    checkToShow(getUserAgentInfo()),
  );

  return device?.name || Device.Unknown;
};
