import { USER_ID_KEY } from '~/constants';
import { AnalyticsEventNames } from '~/services/analytics/types';
import AbstractStorage from '~/services/storage/AbstractStorage';

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

export interface AnalyticsItem {
  init: (userId: string | null) => void;
  trackEvent: (name: string, parameters?: Record<string, any>) => void;
  trackPurchase: (name: string, parameters: Record<string, any>) => void;
  setUserProperties?: (properties: Record<string, any>) => void;
}

class AnalyticManager {
  subscribers: AnalyticsItem[];

  userId: string | null;

  defaultEventParams: Record<string, any>;

  isInitialized: boolean;

  storage: AbstractStorage;

  debouncedEventName: string | null;

  debounceTimer: null | number;

  constructor(subscribers: AnalyticsItem[], storage: AbstractStorage) {
    this.isInitialized = false;
    this.storage = storage;
    this.subscribers = subscribers || [];
    this.userId = null;
    this.defaultEventParams = {};
  }

  init(userId: string | null, defaultParams: Record<string, any>): void {
    this.userId = userId;
    this.defaultEventParams = defaultParams;
    this.subscribers.forEach((subscriber) => {
      subscriber.init(this.userId);
    });
    this.storage.setByKey(USER_ID_KEY, this.userId);
    this.isInitialized = true;
    if (IS_DEVELOPMENT) {
      console.warn('update analytics user id: ', this.userId);
    }
  }

  addSubscriber(analyticItem: AnalyticsItem): void {
    this.subscribers.push(analyticItem);
  }

  checkAndUpdateUserId(newUserId: string | null): void {
    if (!this.isInitialized) return;
    const prevUserId = this.storage.getByKey(USER_ID_KEY);

    if (newUserId !== prevUserId) {
      this.init(newUserId, this.defaultEventParams);
    }
  }

  addDefaultEventParams(params: Record<string, string>): void {
    if (!this.isInitialized) return;

    this.defaultEventParams = { ...this.defaultEventParams, ...params };
  }

  setUserProperties(properties: Record<string, any>): void {
    if (!this.isInitialized) return;

    this.subscribers.forEach((subscriber) => {
      subscriber.setUserProperties && subscriber.setUserProperties(properties);
    });
  }

  trackEvent(
    name: AnalyticsEventNames,
    parameters?: Record<string, any>,
    skipDebounce = false,
  ): void {
    if (!this.isInitialized) {
      return;
    }

    if (!skipDebounce && this.debouncedEventName === name) {
      return;
    }

    this.debouncedEventName = name;

    this.debounceTimer && clearTimeout(this.debounceTimer);

    this.debounceTimer = window.setTimeout(() => {
      this.debouncedEventName = null;
    }, 1000);

    const extraParams = { ...this.defaultEventParams, ...parameters };

    this.subscribers.forEach((subscriber) => {
      subscriber.trackEvent(name, extraParams);
    });
  }

  trackPurchase(
    name: AnalyticsEventNames,
    parameters: Record<string, any>,
  ): void {
    const extraParams = { ...this.defaultEventParams, ...parameters };

    if (IS_DEVELOPMENT) {
      console.warn('track event name: ', name, extraParams);
    }

    this.subscribers.forEach((subscriber) => {
      subscriber.trackPurchase(name, extraParams);
    });
  }
}

export default AnalyticManager;
