import React, { FC, useMemo } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import router from '~/core/router';
import { useAppSelector } from '~/store/hooks/useAppSelector';

interface Props {
  Component: FC;
}

const AuthRoute: FC<Props> = ({ Component }) => {
  const { isLoggedIn } = useAppSelector((state) => state.profile);

  const location = useLocation();

  const redirectPath: string | null | undefined = useMemo(() => {
    if (!isLoggedIn) {
      return null;
    }
    return (
      location.state?.from ||
      router.getRedirectSignUpPath() ||
      router.getDefaultPrivateRoutePath()
    );
  }, [isLoggedIn]);

  return redirectPath ? (
    <Navigate
      to={redirectPath}
      state={{
        from: redirectPath,
        extension: location.search.search('extension') === 1,
      }}
    />
  ) : (
    <Component />
  );
};

export default AuthRoute;
