import React from 'react';

import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

import { colors } from '~/theme/colors';

const BlackTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.neutral_20,
    fontSize: '12px',
    color: colors.neutral_80,
    fontWeight: '400',
    borderRadius: '4px',
    padding: '4px 8px',
  },
}));

export default BlackTooltip;
