import React, { FC } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import router from '~/core/router';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { FeaturesFields } from '~/types/profile';

interface Props {
  Component: FC;
  checkAvailability?: (features: FeaturesFields) => boolean;
}

const PrivateRoute: FC<Props> = ({ Component, checkAvailability }) => {
  const { isLoggedIn, user_features } = useAppSelector(
    (state) => state.profile,
  );
  const signInPath = router.getSignInRoutePath();
  const { pathname, search } = useLocation();

  return isLoggedIn ? (
    <>
      {checkAvailability ? (
        checkAvailability(user_features) ? (
          <Component />
        ) : (
          <Navigate to={router.getDefaultPrivateRoutePath()} />
        )
      ) : (
        <Component />
      )}
    </>
  ) : (
    <Navigate
      to={signInPath}
      state={{
        from: pathname + (search ? search : ''),
      }}
    />
  );
};

export default PrivateRoute;
