import React, { FC, ReactElement, useRef, useState } from 'react';

import { Icon } from '@iconify/react';
import { Collapse, List, ListItem, styled, Typography } from '@mui/material';
import clsx from 'clsx';

import useClickOutside from '~/hooks/useClickOutside';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

export const StyledList = styled(List)(() => ({
  padding: '0',
  borderRadius: '8px',
  backgroundColor: colors.neutral_80,
}));

export const StyledListItem = styled(ListItem)(() => ({
  overflow: 'hidden',
  display: 'flex',
  padding: '14px 12px',
  gap: '8px',
  fontSize: '15px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  cursor: 'pointer',

  '&:hover': {
    background: 'rgba(29, 27, 32, 0.08)',
  },
}));

export type DropdownOption<T> = { value: T; text: string };

type Props<T> = {
  options: DropdownOption<T>[];
  selectedOption: DropdownOption<T>;
  onOptionClick: (opt: DropdownOption<T>) => void;
  variant?: 'text' | 'outlined' | 'secondary';
  selectClassName?: string;
  valueClassName?: string;
  iconClassName?: string;
  IconComponent?: FC<{ className?: string }>;
  iconPosition?: 'start' | 'end';
  isDisabled?: boolean;
};

const DropdownCustom: <T>(props: Props<T>) => React.ReactElement<Props<T>> = ({
  options,
  selectedOption,
  onOptionClick,
  variant = 'outlined',
  selectClassName,
  valueClassName,
  iconClassName,
  IconComponent = (props): ReactElement => (
    <Icon icon="material-symbols:expand-more" {...props} />
  ),
  iconPosition = 'end',
  isDisabled,
}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useClickOutside({
    refs: [dropdownRef],
    callback: () => setIsMenuOpened(false),
  });

  const handleSelectClick = (): void => {
    setIsMenuOpened((prev) => !prev);
  };

  const handleOptionClick = (opt): void => {
    setIsMenuOpened(false);
    if (selectedOption.value === opt.value) {
      return;
    }

    onOptionClick(opt);
  };

  return (
    <div
      ref={dropdownRef}
      className={clsx(styles.select, styles[variant], selectClassName, {
        [styles.is_focused]: isMenuOpened,
        [styles.is_disabled]: isDisabled,
      })}
    >
      <Typography
        variant={Typographies.BODY_LARGE}
        className={clsx(styles.select__value, valueClassName, {
          [styles.is_disabled]: isDisabled,
        })}
        onClick={handleSelectClick}
        component="div"
      >
        {iconPosition === 'start' && (
          <IconComponent className={clsx(styles.select__icon, iconClassName)} />
        )}
        <span>{selectedOption.text}</span>
        {iconPosition === 'end' && (
          <IconComponent className={clsx(styles.select__icon, iconClassName)} />
        )}
      </Typography>

      <div className={styles.select__dropdown}>
        <Collapse in={isMenuOpened}>
          <StyledList>
            {options.map((option) => (
              <StyledListItem
                key={option.text}
                onClick={(): void => handleOptionClick(option)}
                className={styles.select__option}
              >
                {option.text}
              </StyledListItem>
            ))}
          </StyledList>
        </Collapse>
      </div>
    </div>
  );
};

export default DropdownCustom;
