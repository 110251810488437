import React, { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';

import Api from '~/Api';
import Button from '~/components/atoms/buttons/Button';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import HookFormPassword from '~/components/hookFormControls/PasswordControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import useErrors from '~/hooks/useErrors';
import { useAppSelector } from '~/store/hooks/useAppSelector';

import { formSchema } from './formSchema';

type Props = {
  onCancel: () => void;
  onSuccessDeletion: () => void;
};

type FormDataType = {
  phrase: string;
  password: string;
};
const DeleteAllDataConfirmationModal: FC<Props> = ({
  onCancel,
  onSuccessDeletion,
}) => {
  const profile = useAppSelector((state) => state.profile);
  const { reportUserErrors } = useErrors();
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<FormDataType>({
    resolver: yupResolver(formSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { handleSubmit, setError } = form;

  const handleSuccessSubmit = async ({
    password,
  }: FormDataType): Promise<void> => {
    setIsLoading(true);
    try {
      await Api.checkUserPassword(password);
      await Api.deleteAccount(profile.id);
      setIsLoading(false);
      onSuccessDeletion();
    } catch (e: any) {
      if (e.message === 'Your password is incorrect') {
        setError('password', { message: e.message });
      } else {
        reportUserErrors({
          error: e,
          method: 'deleteAccount',
          userMessage: 'Error deleting account. Try again later or',
        });
      }

      setIsLoading(false);
    }
  };

  return (
    <div>
      <ModalContentTitle text="Confirmation required" />
      <ModalContentText
        sx={{
          marginBottom: '24px',
        }}
      >
        For final verification enter this confirmation phrase below:&nbsp;
        <b>Delete My Account</b>
      </ModalContentText>
      <FormProvider {...form}>
        <HookFormTextField
          InputLabelProps={{ shrink: true }}
          name="phrase"
          label="Confirmation phrase"
          placeholder='Type "Delete My Account"'
          disabled={isLoading}
          fullWidth
          sx={{
            marginBottom: '40px',
          }}
        />
        <HookFormPassword
          InputLabelProps={{ shrink: true }}
          name="password"
          label="Your current password"
          placeholder="Enter your password"
          disabled={isLoading}
          fullWidth
          sx={{
            marginBottom: '40px',
          }}
        />
      </FormProvider>
      <div>
        <Button disabled={isLoading} onClick={onCancel} variant="contained">
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleSubmit(handleSuccessSubmit)}
          variant="text"
        >
          Delete account forever
        </Button>
      </div>
    </div>
  );
};

export default DeleteAllDataConfirmationModal;
