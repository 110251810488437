import { ReasonType } from '../../types';

type FormOptionsType = {
  reasons: ReasonType[];
  sub_reasons: Record<Exclude<ReasonType, 'Other'>, string[]>;
  relations: string[];
  feedbacks: Record<Exclude<ReasonType, 'Other'>, string>;
};

export const formOptions: FormOptionsType = {
  reasons: [
    'Support Experience',
    'Product Reliability',
    'Cost and Value',
    'Product Features and Functionality',
    'Usability and Design',
    'Other',
  ],
  sub_reasons: {
    'Support Experience': [
      'Support was slow or unresponsive',
      'Guidance provided was unhelpful',
      "My issue wasn't resolved",
    ],
    'Product Reliability': [
      'There were frequent bugs or glitches',
      'Performance was slow or unreliable',
      'It was difficult to get started with the product',
    ],
    'Cost and Value': [
      'The product was too expensive',
      "It didn't deliver enough value for the price",
      'There were issues with billing or subscription',
      "The trial period didn't meet my expectations",
    ],
    'Product Features and Functionality': [
      'The product was missing key features',
      'It needed more customization options',
      "It didn't integrate well with other tools",
      "The product didn't meet my goals",
    ],
    'Usability and Design': [
      'The product was difficult to use',
      'The design or layout was unappealing',
      'There were issues with language support',
    ],
  },
  relations: [],
  feedbacks: {
    'Support Experience':
      'Tell us where our support could do better: Was it the wait, the clarity, the outcome?',
    'Product Reliability':
      'What tech hiccups did you face: errors, speed or downtime?',
    'Cost and Value':
      'Help us understand the value issue: Was it budget, alternatives, usage, or perceived value?',
    'Product Features and Functionality':
      'Tell us where which feature fell short? Was something missing?',
    'Usability and Design':
      'Was it easy to get around? Tell us about navigation, design, accessibility, or layout preferences.',
  },
};
