import React, { FC } from 'react';

import clsx from 'clsx';

import Logo from '~/components/atoms/Logo';

import styles from './styles.module.scss';

type Props = {
  children?: React.ReactNode;
  headerChildren?: React.ReactNode;
  className?: string;
};

const SimpleHeaderLogoLayout: FC<Props> = ({
  children,
  headerChildren,
  className,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Logo />
        {headerChildren}
      </div>
      <div className={clsx(styles.content, className)}>{children}</div>
    </div>
  );
};

export default SimpleHeaderLogoLayout;
