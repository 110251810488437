import React, { FC } from 'react';

import Loader from '~/components/atoms/Loader';

const FullscreenPreloader: FC = () => {
  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Loader />
    </div>
  );
};

export default FullscreenPreloader;
