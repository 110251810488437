import React, { FC } from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';

type Props = {
  isLoading?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const Loader: FC<Props> = ({ className, isLoading = true, children }) => {
  return isLoading ? (
    <div className={styles.loader_wrapper}>
      <div className={clsx(styles.loader, className)} />
    </div>
  ) : (
    <>{children}</>
  );
};

export default Loader;
