import React, { FC, ReactElement } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getExtraProductDescriptions } from '~/components/organisms/PaymentForm/components/OrderDescription/constants';
import i18next from '~/i18n/config';
import extension from '~/services/Extension';
import { Typographies } from '~/theme/typography';
import { LimitsRenewPeriod } from '~/types/common';

import styles from './styles.module.scss';

const { t } = i18next;

export type OrderDescriptionType = {
  details_list_ui: Array<string | ReactElement>;
  image_src_ui: string;
};

export type Props = {
  is_popular_ui: boolean;
  product_id: string;
  product_name: string;
};

const defaultDetailsUi = [
  <>
    <Icon icon="foundation:check" className={styles.detail__icon} />
    <span>
      {t(
        'subscription:payment_form.order_details_default[0]',
        'Unlimited Words for 120+ Advanced AI Tools',
      )}
    </span>
  </>,
  <>
    <Icon icon="foundation:check" className={styles.detail__icon} />
    <span>
      {t(
        'subscription:payment_form.order_details_default[1]',
        '2000+ word long Article Generation Tool',
      )}
    </span>
  </>,
  <>
    <Icon icon="foundation:check" className={styles.detail__icon} />
    <span>
      {t(
        'subscription:payment_form.order_details_default[2]',
        'Plagiarism Checker and AI Detector',
      )}
    </span>
  </>,
  <>
    <Icon icon="foundation:check" className={styles.detail__icon} />
    <span>
      {t('subscription:payment_form.order_details_default[3]', {
        title: extension.getTitle(),
        defaultValue: 'Ultimate {{title}} Extension',
      })}
    </span>
  </>,
];

const OrderDescription: FC<Props> = ({
  is_popular_ui,
  product_name,
  product_id,
}) => {
  const { t } = useTranslation('subscription');

  const extraProductDescription = getExtraProductDescriptions(
    product_id,
    LimitsRenewPeriod.MONTH,
  );

  return (
    <div className={styles.wrapper}>
      {is_popular_ui && (
        <Typography
          className={styles.popular}
          variant={Typographies.BODY_SMALL}
          component="p"
        >
          {t('general:most_popular', 'Most Popular')}
        </Typography>
      )}

      {extraProductDescription?.image_src_ui && (
        <img
          src={extraProductDescription.image_src_ui}
          alt=""
          className={styles.image}
        />
      )}

      <Typography
        className={styles.title}
        variant={Typographies.TITLE_MEDIUM}
        component="h3"
      >
        {product_name}
      </Typography>

      <Typography
        className={styles.details_title}
        variant={Typographies.LABEL_LARGE}
        component="h3"
      >
        {t('payment_form.order_details_title', 'Your order details:')}
      </Typography>

      <div>
        {(extraProductDescription?.details_list_ui || defaultDetailsUi).map(
          (detail, idx) => (
            <Typography
              key={idx}
              className={styles.detail}
              variant={Typographies.BODY_MEDIUM}
              component="h3"
            >
              {detail}
            </Typography>
          ),
        )}
      </div>
    </div>
  );
};

export default OrderDescription;
