import React, { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import API from '~/Api';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { ProfileActionTypes } from '~/store/types/profile';

type Props = {
  children?: React.ReactNode;
};

const UPDATE_PROFILE_INTERVAL = 300000; // default 300000 (5min)

let interval: NodeJS.Timeout | null = null;

const ProfileStatusWrapper: FC<Props> = ({ children }) => {
  const { isLoggedIn } = useAppSelector(
    (state) => state.profile,
    (state1, state2) => state1.isLoggedIn !== state2.isLoggedIn,
  );
  const dispatch = useDispatch();
  const load = async (): Promise<void> => {
    try {
      const profile = await API.getProfile();
      dispatch({
        type: ProfileActionTypes.UPDATE_PROFILE,
        payload: {
          ...profile,
        },
      });
    } catch (e) {
      console.error(e); // it doesn't necessary to show user anything at this place
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      interval = setInterval(load, UPDATE_PROFILE_INTERVAL);
    } else {
      interval && clearInterval(interval);
    }

    return (): void => {
      interval && clearInterval(interval);
    };
  }, [isLoggedIn]);

  return <>{children}</>;
};

export default ProfileStatusWrapper;
