import { Popover, PopoverProps, styled } from '@mui/material';

import { colors } from '~/theme/colors';

const StyledPopover = styled(Popover)<PopoverProps>(() => ({
  '& .MuiPaper-root': {
    backgroundColor: colors.neutral_80,
  },
}));
export default StyledPopover;
