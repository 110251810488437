import React, { FC, Fragment } from 'react';

import { FormControlLabel, FormControlLabelProps, styled } from '@mui/material';
import MuiRadio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';

const StyledRadio = styled(FormControlLabel)<
  FormControlLabelProps & { error: boolean }
>(({ error }) => ({
  padding: '12px 16px',
  margin: 0,

  '& .MuiRadio-root': {
    width: '24px',
    height: '24px',
    padding: '0',
    marginRight: '16px',
    ...(error && { color: '#BA1A1A' }),
  },

  '& .MuiFormControlLabel-label': {
    ...(error && { color: '#BA1A1A' }),
  },
}));

type Props = {
  errorHelperText?: string;
  name: string;
  value: string;
  onChange: (e) => void;
  options: string[];
};

const RadioGroup: FC<Props> = ({
  errorHelperText,
  name,
  value,
  onChange,
  options,
}) => {
  return (
    <MuiRadioGroup name={name} value={value} onChange={onChange}>
      {options.map((option, idx) => (
        <Fragment key={idx}>
          <StyledRadio
            value={option}
            control={<MuiRadio />}
            label={option}
            error={!!errorHelperText}
          />
        </Fragment>
      ))}
      {errorHelperText && (
        <span
          style={{
            fontSize: '12px',
            color: '#BA1A1A',
            marginTop: '3px',
            marginInline: '10px',
          }}
        >
          {errorHelperText}
        </span>
      )}
    </MuiRadioGroup>
  );
};

export default RadioGroup;
