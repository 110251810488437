import React, { FC } from 'react';

import { Typography } from '@mui/material';

import Button from '~/components/atoms/buttons/Button';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import StaticUserBenefits from '~/components/molecules/StaticUserBenefits';
import ViewAllPlansLink from '~/modules/settings/components/modals/ChooseSubscriptionModal/elements/ViewAllPlansLink';
import ProductCard from '~/modules/settings/components/ProductCard';
import { Typographies } from '~/theme/typography';
import { ProductType } from '~/types/product';

import styles from './styles.module.scss';

type Props = {
  product: ProductType;
  onViewPlansClick?: () => void;
  onSubmit: () => void;
};

const StatsBenefitsVariant: FC<Props> = ({
  product,
  onViewPlansClick,
  onSubmit,
}) => {
  return (
    <div>
      <ModalContentTitle
        text="Renew your subscription to continue being more productive!"
        className={styles.title}
      />

      <Typography
        className={styles.text}
        variant={Typographies.TITLE_MEDIUM}
        component="p"
      >
        Each month, users with this subscription typically:
      </Typography>

      <div className={styles.benefits}>
        <StaticUserBenefits />
      </div>

      <div className={styles.product}>
        <ProductCard isSelectable isAccent isActive product={product} />
      </div>

      <Button
        variant="contained"
        color="gradient"
        size="large"
        fullWidth
        onClick={onSubmit}
      >
        Restore Subscription
      </Button>

      {onViewPlansClick && <ViewAllPlansLink onClick={onViewPlansClick} />}
    </div>
  );
};

export default StatsBenefitsVariant;
