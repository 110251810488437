export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SORT_BY {
  NAME = 'name',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  EMAIL = 'email',
  STATUS = 'status',
  ID = 'id',
  CLIENT_COUNT = 'clients_count',
}

export enum COPY_METHOD {
  MANUALLY = 'manually',
  INTERFACED = 'interfaced',
}

export enum COMMUNICATION_TYPE {
  RESPONSE = 'response',
  REQUEST = 'request',
}

export type MetaType = {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
};

export enum LimitsRenewPeriod {
  MONTH = 'month',
  WEEK = 'week',
}

export const DEFAULT_STATUS = 'All';
export type DEFAULT_STATUS_TYPE = 'All';
