import * as amplitude from '@amplitude/analytics-browser';
import { Types } from '@amplitude/analytics-browser';

import { AnalyticsItem } from '../AnalyticManager';

class Amplitude implements AnalyticsItem {
  apiKey: string;

  amplitudeClient: Types.BrowserClient;

  constructor(API_KEY: string) {
    this.apiKey = API_KEY;
    this.amplitudeClient = amplitude.createInstance();
  }

  init(userId: string | null): void {
    this.amplitudeClient.init(this.apiKey, userId ? userId : undefined);
  }

  trackEvent(eventName: string, parameters?: Record<string, any>): void {
    this.amplitudeClient.logEvent(eventName, parameters);
  }

  setUserProperties(properties: Record<string, string | number | null>): void {
    const identify = new amplitude.Identify();
    Object.entries(properties).forEach(([key, value]) => {
      if (value) {
        identify.set(key, value);
      } else {
        identify.unset(key);
      }
    });
    this.amplitudeClient.identify(identify);
  }

  trackPurchase(eventName: string, parameters: Record<string, any>): void {
    const revenue = new amplitude.Revenue()
      .setQuantity(1)
      .setPrice(parameters.value)
      .setRevenueType('purchase')
      .setProductId(parameters.product_id)
      .setEventProperties({ currency: 'USD' });

    this.trackEvent(eventName, {
      ...parameters,
      amount: parameters.value,
    });

    this.amplitudeClient.revenue(revenue);
  }
}

export default Amplitude;
