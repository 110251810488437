// 'hello_world' -> 'Hello World'
export const snakeCaseToCapitalizeCase = (input: string): string => {
  return input
    .replace(/_/g, ' ')
    .replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1));
};

// 'Hello World' -> 'hello_world'
export const capitalizeCaseToSnakeCase = (input: string): string => {
  return input.replace(/\s+/g, '_').toLowerCase();
};

export const whiteSpaceToUnderscoreCase = (input: string): string => {
  return input.split(' ').join('_');
};

export const capitalize = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};
