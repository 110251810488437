import AbstractStorage from './AbstractStorage';

class SessionStorage extends AbstractStorage {
  setByKey(key: string, value: string | null): void {
    if (!value) return;
    window.sessionStorage.setItem(key, value);
  }

  getByKey(key: string): string | null {
    return window.sessionStorage.getItem(key) || null;
  }

  removeByKey(key: string): void {
    window.sessionStorage.removeItem(key);
  }
}

const sessionStorage = new SessionStorage();

export default sessionStorage;
