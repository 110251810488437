import React, { ReactElement } from 'react';

import { Icon as SidebarIcon } from '@iconify/react';
import { flowRight } from 'lodash';

import router, { RouteItemType } from '~/core/router';
import { sidebar } from '~/core/sidebar';
import {
  withHomeScreenRetentionLayout,
  withMainLayout,
  withOnboardingLayout,
  withPWAModalLayout,
  withSubModalsLayout,
} from '~/decorators/withLayout';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import {
  ImageGeneratorPath,
  ImageGeneratorSidebarKey,
  ImageGeneratorTitle,
} from '~/modules/image-generator/constants';

const ImageGenerator = lazyComponentLoader(() => import('./ImageGenerator'));

const ImageGeneratorRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(ImageGenerator),
  path: ImageGeneratorPath,
  title: ImageGeneratorTitle,
};

router.addPrivateRoutes([ImageGeneratorRoute]);

sidebar.addItems({
  a: [
    {
      order: 4,
      key: ImageGeneratorSidebarKey,
      path: ImageGeneratorRoute.path,
      title: ImageGeneratorRoute.title,
      label: 'NEW',
      Icon: ({ isActive }): ReactElement => {
        return (
          <SidebarIcon
            icon={isActive ? 'mdi:paintbrush' : 'mdi:paintbrush-outline'}
          />
        );
      },
    },
  ],
});
