import { ProfileType } from '~/types/profile';

export type ProfileState = ProfileType & {
  isLoggedIn: boolean;
};

export enum ProfileActionTypes {
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  SIGN_OUT = 'SIGN_OUT',
  SIGN_IN = 'SIGN_IN',
  INCREASE_RESPONSE_COUNT = 'INCREASE_RESPONSE_COUNT',
}

interface UpdateProfileAction {
  type: ProfileActionTypes.UPDATE_PROFILE;
  payload: Partial<ProfileState>;
}

interface SignInProfileAction {
  type: ProfileActionTypes.SIGN_IN;
}

interface SignOutProfileAction {
  type: ProfileActionTypes.SIGN_OUT;
}

interface IncreaseResponseCountAction {
  type: ProfileActionTypes.INCREASE_RESPONSE_COUNT;
}

export type ProfileAction =
  | UpdateProfileAction
  | SignInProfileAction
  | SignOutProfileAction
  | IncreaseResponseCountAction;
