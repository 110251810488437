import React, { FC, Fragment } from 'react';

import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import ListOption from '~/components/atoms/ListOption';
import { SideBarItemType } from '~/core/sidebar';
import { checkIsRouteMatch } from '~/helpers/routeMatcher';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type ExtendedSidebarItemType = Omit<SideBarItemType, 'key'> & {
  onClick?: () => void;
};

type Props = {
  groups: ExtendedSidebarItemType[][];
  onClickItem?: () => void;
};

const InnerNavigation: FC<Props> = ({ groups, onClickItem }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleListOptionClick = (path: string, callback?: () => void): void => {
    callback && callback();
    navigate(path);
    onClickItem && onClickItem();
  };

  return (
    <Fragment>
      {groups.map((items, groupIndex) => (
        <Fragment key={groupIndex}>
          {items.map(({ path, redirectPath, Icon, title, onClick }) => (
            <Fragment key={path}>
              <ListOption
                size="small"
                onClick={(): void =>
                  handleListOptionClick(redirectPath || path, onClick)
                }
                isActive={checkIsRouteMatch(path, location.pathname)}
              >
                <div className={styles.nav__item_icon}>
                  <Icon />
                </div>

                <Typography variant={Typographies.TITLE_SMALL} component="p">
                  {title}
                </Typography>
              </ListOption>
            </Fragment>
          ))}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default InnerNavigation;
