import React, { FC, useMemo } from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ModalContextProvider } from '~/contexts/modal/ModalContext';
import { PopoverContextProvider } from '~/contexts/popover/PopoverContext';
import { SnackBarContextProvider } from '~/features/snackbar/SnackbarContext';
import { CheckAuthWrapper } from '~/middlewares/CheckAuthWrapper';
import ProfileStatusWrapper from '~/middlewares/ProfileStatusWrapper';
import { SubRebillWrapper } from '~/middlewares/SubRebillWrapper';
import { HighlightContextProvider } from '~/modules/highlight/HighlightContext';
import Router from '~/Router';
import GrowthBookProvider from '~/services/abTests/Provider';
import { store } from '~/store';
import createCustomTheme from '~/theme';

const themeMode = 'light';
const App: FC = () => {
  const theme = useMemo(() => createCustomTheme(themeMode), [themeMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <BrowserRouter>
          <SnackBarContextProvider>
            <ModalContextProvider>
              <PopoverContextProvider>
                <HighlightContextProvider>
                  <CheckAuthWrapper>
                    <GrowthBookProvider>
                      <ProfileStatusWrapper>
                        <SubRebillWrapper>
                          <Router />
                        </SubRebillWrapper>
                      </ProfileStatusWrapper>
                    </GrowthBookProvider>
                  </CheckAuthWrapper>
                </HighlightContextProvider>
              </PopoverContextProvider>
            </ModalContextProvider>
          </SnackBarContextProvider>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
