import React, { FC } from 'react';

import clsx from 'clsx';

import InfoLabel from '~/components/atoms/InfoLabel';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';

import styles from './styles.module.scss';

type Props = {
  Icon: FC<{ isActive?: boolean }>;
  title: string;
  isActive: boolean;
  label?: number | string;
  onClick: () => void;
};

const SideBarItem: FC<Props> = ({ Icon, title, isActive, label, onClick }) => {
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  const IconWithLabel: FC = () => (
    <div className={styles.item__icon_container}>
      <Icon isActive={isActive} />
      {label && (
        <InfoLabel
          className={styles.item__icon_container__label}
          text={label.toString()}
        />
      )}
    </div>
  );

  return (
    <div
      onClick={(): void => onClick()}
      className={clsx(styles.item__outer_wrapper, {
        [styles.is_active]: isActive,
      })}
    >
      {!isDesktop ? (
        <div className={styles.item__inner_wrapper}>
          <IconWithLabel />
          <span className={styles.item__title}>{title}</span>
        </div>
      ) : (
        <>
          <div className={styles.item__inner_wrapper}>
            <IconWithLabel />
          </div>
          <span className={styles.item__title}>{title}</span>
        </>
      )}
    </div>
  );
};

export default SideBarItem;
