import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const UpdatePaymentDescription: FC = () => {
  return (
    <div className={styles.wrapper}>
      <Typography
        className={styles.label}
        variant={Typographies.BODY_SMALL}
        component="p"
      >
        Verification Transaction
      </Typography>

      <Typography
        className={styles.title}
        variant={Typographies.TITLE_MEDIUM}
        component="h3"
      >
        Updating Payment method
      </Typography>

      <Typography
        className={styles.details_title}
        variant={Typographies.LABEL_LARGE}
        component="h3"
      >
        This transaction is to verify and update your payment method for future
        use.
        <br />
        <br />
        This helps ensure your payment information is active and valid.
      </Typography>
    </div>
  );
};

export default UpdatePaymentDescription;
