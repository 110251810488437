import router, { RouteItemType } from '~/core/router';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import { PromoPath, PromoTitle } from '~/modules/promotion/constants';

const PromoCheckoutComponent = lazyComponentLoader(
  () => import('./PromoCheckout'),
);

const Promo: RouteItemType = {
  Component: PromoCheckoutComponent,
  path: PromoPath,
  title: PromoTitle,
};

router.addPrivateRoutes([Promo]);
