import React, { FC, ReactNode, useMemo, useState } from 'react';

import { Icon } from '@iconify/react';
import { Popover, styled } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import IconButton from '~/components/atoms/buttons/IconButton';
import FallbackLetterAvatar from '~/components/atoms/FallbackLetterAvatar';
import Logo from '~/components/atoms/Logo';
import SidebarItem from '~/components/atoms/SidebarItem';
import ButtonSuggestion from '~/components/layouts/MainLayout/components/ButtonSuggestion';
import InnerNavigation from '~/components/molecules/InnerNavigation';
import { AvatarType } from '~/components/molecules/LogoMessageWrapper';
import SupportModal from '~/components/molecules/SupportModal';
import { useModalContext } from '~/contexts/modal/ModalContext';
import { settingsSidebar, SideBarItemType } from '~/core/sidebar';
import { checkIsRouteMatch } from '~/helpers/routeMatcher';
import analytics from '~/services/analytics';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';

import styles from './styles.module.scss';

const StyledPopover = styled(Popover)(() => ({
  '.MuiPopover-paper': {
    margin: 0,
  },
}));

type Props = {
  items: SideBarItemType[][];
  onClickClose: () => void;
};

const SidebarMobile: FC<Props> = ({ items, onClickClose }) => {
  const { signOut } = useActions();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleOpenModal } = useModalContext();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const user = useAppSelector((state) => state.profile);

  const handleClickHelp = (): void => {
    analytics.trackEvent('navigation - open help section');

    handleOpenModal({
      onClose: () => analytics.trackEvent('navigation - close support modal'),
      component: ({ onClose }) => <SupportModal onClose={onClose} />,
    });
  };

  const settingsGroups = useMemo(() => {
    return [
      ...Object.values({
        ...settingsSidebar.getItems(),
        a: [
          ...settingsSidebar.getItems()['a'],
          {
            path: '#',
            Icon: (): ReactNode => (
              <Icon icon="material-symbols:help-outline" />
            ),
            title: 'Help',
            onClick: (): void => {
              handleClickHelp();
            },
          },
        ],
      }),
      [
        {
          path: '#',
          Icon: (): ReactNode => <Icon icon="material-symbols:logout" />,
          title: 'Logout',
          onClick: (): void => {
            signOut();
          },
        },
      ],
    ];
  }, [settingsSidebar]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <IconButton
          className={styles.sidebar__close_button}
          onClick={onClickClose}
          size="small"
        >
          <Icon icon="material-symbols:close" width="24" color="#45464F" />
        </IconButton>

        <div className={styles.header__logo}>
          <Logo />
        </div>
      </div>

      <aside className={styles.sidebar}>
        <div
          onClick={(e): void => setAnchorEl(e.currentTarget)}
          className={styles.sidebar__account_row}
        >
          <div className={styles.sidebar__flex_container}>
            <FallbackLetterAvatar
              logo_src={user.avatar}
              className={styles.sidebar__account_row__avatar}
              variant={AvatarType.USER}
            >
              {user.name.charAt(0)}
            </FallbackLetterAvatar>

            <p className={styles.sidebar__account_row__title}>{user.name}</p>
          </div>

          <Icon
            className={styles.sidebar__account_row__icon}
            icon="material-symbols:unfold-more"
          />
        </div>

        <StyledPopover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={(): void => setAnchorEl(null)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: { width: '268px' },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <InnerNavigation groups={settingsGroups} onClickItem={onClickClose} />
        </StyledPopover>

        <div className={styles.sidebar__title}>Features</div>

        <div className={styles.sidebar__items}>
          {items.map((items, i) => (
            <div key={i}>
              {items.map(({ path, redirectPath, Icon, title, label }) => (
                <SidebarItem
                  isActive={checkIsRouteMatch(path, location.pathname)}
                  key={path}
                  Icon={Icon}
                  onClick={(): void => {
                    navigate(redirectPath || path);
                    onClickClose();
                  }}
                  title={title}
                  {...(label ? { label: label } : {})}
                />
              ))}
            </div>
          ))}
        </div>

        <div className={styles.buttons_panel}>
          <ButtonSuggestion />
        </div>
      </aside>
    </div>
  );
};

export default SidebarMobile;
