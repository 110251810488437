import React, { ReactElement } from 'react';

import { Icon } from '@iconify/react';
import { flowRight } from 'lodash';

import router from '~/core/router';
import { settingsSidebar } from '~/core/sidebar';
import {
  withHomeScreenRetentionLayout,
  withMainSimpleLayout,
  withOnboardingLayout,
  withPWAModalLayout,
  withSettingsNavigationLayout,
  withSubModalsLayout,
} from '~/decorators/withLayout';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import {
  PersonalInformationPath,
  PersonalInformationTitle,
  SecurityPath,
  SecurityTitle,
  SubscriptionPath,
  SubscriptionTitle,
} from '~/modules/settings/constants';

const PersonalInformation = lazyComponentLoader(
  () => import('./views/PersonalInformation'),
);

const Security = lazyComponentLoader(() => import('./views/Security'));

const Subscription = lazyComponentLoader(() => import('./views/Subscription'));

const PersonalInformationRoute = {
  title: PersonalInformationTitle,
  path: PersonalInformationPath,
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainSimpleLayout,
    withSettingsNavigationLayout,
  )(PersonalInformation),
};

const SecurityRoute = {
  title: SecurityTitle,
  path: SecurityPath,
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainSimpleLayout,
    withSettingsNavigationLayout,
  )(Security),
};

const SubscriptionRoute = {
  title: SubscriptionTitle,
  path: SubscriptionPath,
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainSimpleLayout,
    withSettingsNavigationLayout,
  )(Subscription),
};

router.addPrivateRoutes([
  PersonalInformationRoute,
  SecurityRoute,
  SubscriptionRoute,
]);

settingsSidebar.addItems({
  a: [
    {
      key: 'personal_information',
      path: PersonalInformationRoute.path,
      title: PersonalInformationRoute.title,
      Icon: (props): ReactElement => (
        <Icon {...props} icon="material-symbols:person-outline" />
      ),
    },
    {
      key: 'security',
      path: SecurityRoute.path,
      title: SecurityRoute.title,
      Icon: (props): ReactElement => (
        <Icon {...props} icon="material-symbols:key-outline" />
      ),
    },
    {
      key: 'subscription',
      path: SubscriptionRoute.path,
      title: SubscriptionRoute.title,
      Icon: (props): ReactElement => (
        <Icon {...props} icon="material-symbols:card-membership-outline" />
      ),
    },
  ],
});
