import React, { FC, useEffect } from 'react';

import DoneImg from '~/assets/images/done_v2.webp';
import DonePreviewImg from '~/assets/images/done_v2_preview.png';
import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import analytics from '~/services/analytics';

import styles from './styles.module.scss';

type Props = {
  onClose: () => void;
};

const PaymentFixed: FC<Props> = ({ onClose }) => {
  useEffect(() => {
    analytics.trackEvent('subscription - fixed redemption issue screen');
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image src={DoneImg} preview={DonePreviewImg} />
      </div>

      <ModalContentTitle className={styles.title} text="Payment Issues Fixed" />

      <ModalContentText sx={{ mb: '32px', maxWidth: 'unset' }}>
        Your payment can now be processed successfully.
      </ModalContentText>

      <Button
        className={styles.button}
        variant="contained"
        color="primary"
        onClick={onClose}
      >
        Got it
      </Button>
    </div>
  );
};

export default PaymentFixed;
