import React, { FC } from 'react';

import clsx from 'clsx';

import { ReactComponent as LogoIcon } from '~/assets/icons/logo.svg';

import styles from './styles.modules.scss';

type Props = {
  className?: string;
};

const Logo: FC<Props> = ({ className }) => {
  return (
    <a href="/" className={clsx(styles.logo, className)}>
      <LogoIcon alt="Just Done" />
    </a>
  );
};

export default Logo;
