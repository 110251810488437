import { useEffect } from 'react';

interface ClickOutsideExcludeButtonProps {
  refs: React.RefObject<HTMLElement>[];
  callback: () => void;
}

const useClickOutside = ({
  refs,
  callback,
}: ClickOutsideExcludeButtonProps): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      const isOutside = refs.every(
        (ref) => ref.current && !ref.current.contains(event.target as Node),
      );

      if (isOutside) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, callback]);
};

export default useClickOutside;
