export const timeout = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const haveSameKeys = (obj1, obj2): boolean => {
  if (!obj1 || !obj2) {
    return false;
  }

  // Convert the arrays of keys to sets for efficient membership checks
  const set1 = new Set(Object.keys(obj1));
  const set2 = new Set(Object.keys(obj2));

  // Check if the sets of keys have the same size
  if (set1.size !== set2.size) {
    return false;
  }

  // Check if set1 contains all keys from set2
  for (const key of set2) {
    if (!set1.has(key)) {
      return false;
    }
  }

  return true;
};

export const hasOwnKey = (obj, key): boolean => {
  return Object.prototype.hasOwnProperty.call(obj, key);
};

export const swapIndices = (array: any[], from: number, to: number): void => {
  const temp = array[from];
  array[from] = array[to];
  array[to] = temp;
};
