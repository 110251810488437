import React, { FC, useMemo, useState } from 'react';

import { Icon } from '@iconify/react';
import { Drawer } from '@mui/material';

import IconButton from '~/components/atoms/buttons/IconButton';
import InfoLabel from '~/components/atoms/InfoLabel';
import Logo from '~/components/atoms/Logo';
import SidebarMobile from '~/components/layouts/MainLayout/Sidebar/mobile';
import { SideBarItemType } from '~/core/sidebar';

import styles from './styles.module.scss';

type Props = {
  sidebarGroups: SideBarItemType[][];
  children?: React.ReactNode;
};

const MobileLayout: FC<Props> = ({ children, sidebarGroups }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isShownBurgerNotify = useMemo(() => {
    return Boolean(
      sidebarGroups.find((group) => group.find((item) => item.label === '1')),
    );
  }, [sidebarGroups]);

  return (
    <>
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.header__burger_button}>
            <IconButton onClick={(): void => setIsOpen(true)} size="small">
              <Icon icon="solar:hamburger-menu-linear" />
            </IconButton>
            {isShownBurgerNotify && (
              <InfoLabel
                className={styles.header__burger_button__label}
                text={'1'}
              />
            )}
          </div>

          <div className={styles.header__logo}>
            <Logo />
          </div>
        </div>

        <div className={styles.content}>{children}</div>
      </div>

      <Drawer
        anchor="left"
        open={isOpen}
        onClose={(): void => setIsOpen(false)}
      >
        <div className={styles.sidebar}>
          <SidebarMobile
            items={sidebarGroups}
            onClickClose={(): void => setIsOpen(false)}
          />
        </div>
      </Drawer>
    </>
  );
};

export default MobileLayout;
