import React, { FC, useMemo, useState } from 'react';

import Image from '~/components/atoms/Image';

import HelpImg from './assets/help_img.webp';
import HelpPreviewImg from './assets/help_img_preview.png';
import AccountDeletionCase from './components/AccountDeletionCase';
import CancellationCase from './components/CancellationCase';
import EntryCase from './components/EntryCase';
import OtherCase from './components/OtherCase';
import styles from './styles.module.scss';

export enum SupportCases {
  ENTRY = 'entry',
  CANCELLATION = 'cancellation',
  ACCOUNT_DELETION = 'account_deletion',
  OTHER = 'other',
}

type Props = {
  onClose: () => void;
};

const SupportModal: FC<Props> = ({ onClose }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<SupportCases[]>([
    SupportCases.ENTRY,
  ]);

  const handleGoNext = (newCase: SupportCases): void => {
    setBreadcrumbs((prev) => [...prev, newCase]);
  };

  const handleGoBack = (): void => {
    setBreadcrumbs(breadcrumbs.slice(0, breadcrumbs.length - 1));
  };

  const CASES = useMemo(() => {
    return {
      [SupportCases.ENTRY]: <EntryCase onNext={handleGoNext} />,
      [SupportCases.CANCELLATION]: (
        <CancellationCase
          onClose={onClose}
          onNext={handleGoNext}
          onBack={handleGoBack}
        />
      ),
      [SupportCases.ACCOUNT_DELETION]: (
        <AccountDeletionCase
          onClose={onClose}
          onNext={handleGoNext}
          onBack={handleGoBack}
        />
      ),
      [SupportCases.OTHER]: (
        <OtherCase onClose={onClose} onBack={handleGoBack} />
      ),
    };
  }, [breadcrumbs.length]);

  return (
    <div>
      <div className={styles.image}>
        <Image src={HelpImg} preview={HelpPreviewImg} />
      </div>

      {CASES[breadcrumbs[breadcrumbs.length - 1]]}
    </div>
  );
};

export default SupportModal;
