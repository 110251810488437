import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

class ErrorLogger {
  isInitialized = false;

  init({
    userId,
    sentryDsn,
  }: {
    userId: string | null;
    sentryDsn: string;
  }): void {
    Sentry.init({
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],
      dsn: sentryDsn,
      // Performance Monitoring
      tracesSampleRate: 0.1,
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    this.isInitialized = true;
    this.setUser(userId);
  }

  captureException(e: Error, description?: string): void {
    if (this.isInitialized) {
      Sentry.captureException(e);
    }
    if (description) {
      console.error(`${description}: `, e);
    } else {
      console.error(e);
    }
  }

  setUser(userId: string | null): void {
    Sentry.setUser(userId ? { id: userId } : null);
  }
}

const errorLogger = new ErrorLogger();

export default errorLogger;
