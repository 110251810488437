import { MessageType } from '~/modules/chat/components/Chat/elements/Message/constants';

export enum CHAT_TYPE {
  SCRAPING_LINK = 'scraping_link',
  SCRAPING_FILE = 'scraping_file',
  DEFAULT = 'standard',
}

export type RecommendedChatPromoToolType = {
  tool_id: string;
  tool_name: string;
  tool_slug: string;
};

export type ChatItemType = {
  id: string;
  name: string;
  current_personality: PERSONALITY;
  created_at: string;
  updated_at: string;
  chat_type: CHAT_TYPE;
};

export enum MESSAGE_CONTEXT {
  DEFAULT = 'default',
  SYSTEM = 'system',
  GOOGLE = 'google',
}

export type SourceOptions = {
  chat_type: CHAT_TYPE;
  source_name: string;
};

export type MessageItemType = {
  id: string;
  type: MessageType;
  body: Record<string, any>;
  created_at: string;
  updated_at: string;
};

export type DTOMessageItemType = {
  message: string;
  message_type: CHAT_TYPE;
  chat_id: string | null;
  personality: PERSONALITY;
  ui_message?: string;
  ui_response?: string;
  message_context: MESSAGE_CONTEXT;
};

export enum PERSONALITY {
  GENERAL = 'general',
  CAREER_COUNSELOR = 'career_counselor',
  MARKETING_DIRECTOR = 'marketing_director',
  MOTIVATIONAL_COACH = 'motivational_coach',
  BUSINESS_ANALYST = 'business_analyst',
  SALES_PROFESSIONAL = 'sales_professional',
  COPYWRITING_MASTER = 'copywriting_master',
}
