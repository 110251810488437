import React, { FC, useMemo, useState } from 'react';

import { Collapse, List, ListItem, styled } from '@mui/material';
import clsx from 'clsx';

import { CARDS } from '~/modules/onboarding-new/constants';
import { colors } from '~/theme/colors';

import styles from './styles.module.scss';

// TODO! Check the problem, why if I use the same components from  '~/components/atoms/StyledMenuItem' and '~/components/atoms/StyledMenuItem'; Menu get extra opacity
export const StyledList = styled(List)(() => ({
  padding: '8px 0',
  borderRadius: '4px',
  backgroundColor: colors.neutral_80,
}));

export const StyledListItem = styled(ListItem)(() => ({
  overflow: 'hidden',
  display: 'flex',
  padding: '14px 12px',
  gap: '8px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  cursor: 'pointer',

  '&:hover': {
    background: 'rgba(29, 27, 32, 0.08)',
  },
}));

type OptionType = {
  text: string;
  value: string;
};

type Props = {
  options: Array<
    OptionType & {
      nextCard?: CARDS;
    }
  >;
  onSubmit: (value: string, nextCard?: CARDS) => void;
  containerClassName?: string;
  placeholder?: string;
};

const Select: FC<Props> = ({
  onSubmit,
  options,
  containerClassName,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const preparedOptions = useMemo(() => {
    const trimmedInputValue = inputValue.trim();
    const filteredOptions = options.filter(({ text }) =>
      text.toLowerCase().includes(inputValue.toLowerCase()),
    );
    return filteredOptions.length
      ? filteredOptions
      : [{ text: trimmedInputValue, value: trimmedInputValue }];
  }, [options, inputValue]);

  return (
    <div className={clsx(styles.main, containerClassName)}>
      <input
        value={inputValue}
        //eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        onChange={(e) => setInputValue(e.target.value)}
        className={styles.main__input}
        type="text"
        placeholder={placeholder}
        onFocus={(): void => setIsMenuOpened(true)}
      />

      <div
        className={clsx(styles.main__menu, {
          [styles['main__menu--visible']]: isMenuOpened,
        })}
      >
        <Collapse in={isMenuOpened}>
          <StyledList>
            {preparedOptions.map(({ value, text, nextCard }) => (
              <StyledListItem
                className={styles.main__menu__item}
                key={value}
                onClick={(): void => onSubmit(value, nextCard)}
              >
                {text}
              </StyledListItem>
            ))}
          </StyledList>
        </Collapse>
      </div>
    </div>
  );
};

export default Select;
