import React, { FC } from 'react';

import Button from '~/components/atoms/buttons/Button';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';

import styles from './styles.module.scss';

type Props = {
  buttonSubmitText: string;
  buttonRefuseText: string;
  onButtonSubmit: (value?: any) => void;
  onButtonRefuse: (value?: any) => void;
  isDisabledSubmitButton?: boolean;
};

const ButtonsBlock: FC<Props> = ({
  buttonSubmitText,
  buttonRefuseText,
  onButtonSubmit,
  onButtonRefuse,
  isDisabledSubmitButton,
}) => {
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  return (
    <div className={styles.buttons}>
      <Button
        variant="contained"
        color="primary"
        onClick={onButtonSubmit}
        fullWidth={!isDesktop}
        className={styles.button}
        disabled={isDisabledSubmitButton}
      >
        {buttonSubmitText}
      </Button>

      <Button
        variant="outlined"
        onClick={onButtonRefuse}
        fullWidth={!isDesktop}
        className={styles.button}
      >
        {buttonRefuseText}
      </Button>
    </div>
  );
};

export default ButtonsBlock;
