import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const Agreement: FC = () => {
  return (
    <div className={styles.agreement}>
      <Typography
        className={styles.text}
        variant={Typographies.BODY_SMALL}
        component="p"
      >
        The payment processed by GM Appdev Limited, with registered address at
        Nafpliou, 15, Limassol, Cyprus, 3025
      </Typography>
    </div>
  );
};

export default Agreement;
