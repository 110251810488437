// here is the place where import new modules
// note! sequence is important!
import '~/modules/auth';
import '~/modules/onboarding-new';
import '~/modules/home';
import '~/modules/chat';
import '~/modules/tools';
import '~/modules/extension';
import '~/modules/image-generator';
import '~/modules/settings';
import '~/modules/cancellation';
import '~/modules/funnel-test';
import '~/modules/promotion';

import { USER_ID_KEY } from '~/constants';
import { growthBook } from '~/services/abTests/growthBook';
import analytics from '~/services/analytics';
import errorLogger from '~/services/ErrorLogger';
import QueryParams from '~/services/QueryParams';
import sessionCounter from '~/services/sessionCounter';
import localStorage from '~/services/storage/localStorage';
// eslint-disable-next-line import/order
import versionUpdater from '~/services/VersionUpdater';

import './i18n/config';
import { getUserBrowser } from '~/utils/userAgentInfo';

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

let userId =
  QueryParams.getQueryParam('user_id') || QueryParams.getQueryParam('email');

const source = QueryParams.getQueryParam('source') || 'web';

if (userId) {
  localStorage.setByKey(USER_ID_KEY, userId);
} else {
  userId = localStorage.getByKey(USER_ID_KEY);
}

// init global analytic
analytics.init(userId, {
  browser: getUserBrowser(),
});
analytics.addDefaultEventParams({ source });

const SENTRY_DSN = process.env.SENTRY_DSN;

if (SENTRY_DSN) {
  errorLogger.init({ userId, sentryDsn: SENTRY_DSN });
}

const GITHUB_SHA = process.env.GITHUB_SHA;

if (GITHUB_SHA && !IS_DEVELOPMENT) {
  versionUpdater.init({ currentVersion: GITHUB_SHA });
}

sessionCounter.init();

const GROWTH_BOOK_API_KEY = process.env.GROWTH_BOOK_API_KEY;

if (GROWTH_BOOK_API_KEY) {
  growthBook.setUp(GROWTH_BOOK_API_KEY, [
    (experiment, result): void => {
      analytics.setUserProperties({
        ['experiment-' + experiment.key]: result.value,
      });
      analytics.trackEvent('experiment - started', {
        ...result,
        ...experiment,
      });
      analytics.trackEvent('experiment - platform started', {
        ...result,
        ...experiment,
      }); // add for test amplitude + growthbook integration
    },
  ]);
}
