import React from 'react';

import { createRoot } from 'react-dom/client';

import './polyfills';
import '~/bootstrap'; // import before App
import FullscreenPreloader from '~/components/atoms/FullscreenPreloader';

import App from './App';

import '~/index.scss';

// eslint-disable-next-line import/no-named-as-default-member
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    {/*Localization. Move Suspense here from Router component*/}
    <React.Suspense fallback={<FullscreenPreloader />}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
);
