import localStorage from '~/services/storage/localStorage';
import sessionStorage from '~/services/storage/sessionStorage';

const OLD_SESSION_KEY = 'IS_JUSTDONE_SESSION';
const COUNT_SESSION_KEY = 'JUSTDONE_SESSION_COUNT';

class SessionCounter {
  private currentCount: number;
  readonly isOldSession: boolean;

  constructor(currentCount: number, isOldSession: boolean) {
    this.currentCount = currentCount;
    this.isOldSession = isOldSession;
  }

  init(): void {
    if (isOldSession) {
      return;
    }

    this.currentCount++;

    localStorage.setByKey(COUNT_SESSION_KEY, String(this.currentCount));
    sessionStorage.setByKey(OLD_SESSION_KEY, '1');
  }

  getCurrentCount(): number {
    return this.currentCount;
  }
}

const countFromLocalStorage = Number(localStorage.getByKey(COUNT_SESSION_KEY));

const isOldSession = Boolean(sessionStorage.getByKey(OLD_SESSION_KEY));

const sessionCounter = new SessionCounter(countFromLocalStorage, isOldSession);

export default sessionCounter;
