import router, { RouteItemType } from '~/core/router';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';

const FunnelTestComponentV1 = lazyComponentLoader(
  () => import('~/modules/funnel-test/v1/components/Index'),
);

const FunnelTestComponentV2 = lazyComponentLoader(
  () => import('~/modules/funnel-test/v2'),
);

const FunnelTest: RouteItemType = {
  Component: FunnelTestComponentV1,
  path: '/funnel-test',
  title: `Funnel Test`,
};

const FunnelTest2: RouteItemType = {
  Component: FunnelTestComponentV2,
  path: '/funnel-test-2',
  title: `Funnel Test`,
};

// shouldn't be available on https:/app.justdone.ai
if (window.location.host !== 'app.justdone.ai') {
  router.addAuthRoutes([FunnelTest]);
  router.addPrivateRoutes([FunnelTest2]);
}
