import Api from '~/Api';

class VersionUpdater {
  checkUpdateIntervalMin = 20; // default 20
  checkUpdateInterval: number | null = null;
  currentVersion: string | null = null;

  init({ currentVersion }: { currentVersion: string }): void {
    this.currentVersion = currentVersion;
    this.run();
  }

  private run(): void {
    if (this.checkUpdateInterval) {
      return;
    }
    this.checkUpdateInterval = window.setInterval(
      async () => {
        const lastVersion = await this.getLastVersion();
        if (lastVersion !== null && lastVersion !== this.currentVersion) {
          window.location.reload();
        }
      },
      this.checkUpdateIntervalMin * 60 * 1000,
    );
  }

  private async getLastVersion(): Promise<string | null> {
    try {
      const { github_sha, need_update } = await Api.getVersion();
      if (need_update) {
        return github_sha;
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}

const versionUpdater = new VersionUpdater();

export default versionUpdater;
