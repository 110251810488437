import React, { FC } from 'react';

import styles from './styles.module.scss';
import SecuredSystemsImg from '../../assets/secured_systems.webp';

const PaymentSystems: FC = () => {
  return (
    <div className={styles.systems}>
      <img
        className={styles.img}
        src={SecuredSystemsImg}
        alt="secured systems"
      />
    </div>
  );
};

export default PaymentSystems;
