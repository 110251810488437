import React, { FC } from 'react';

import Button, { ButtonShape } from '~/components/atoms/buttons/Button';

type Props = {
  onClick: () => void;
  children: React.ReactNode;
};

const SupportCaseOption: FC<Props> = ({ onClick, children }) => (
  <Button
    shape={ButtonShape.ROUNDED}
    variant="outlined"
    color="secondary"
    onClick={onClick}
    sx={{ '&&': { justifyContent: 'flex-start', textAlign: 'left' } }}
  >
    {children}
  </Button>
);

export default SupportCaseOption;
