import React, { FC, useEffect, useState } from 'react';

import FullscreenPreloader from '~/components/atoms/FullscreenPreloader';
import analytics from '~/services/analytics';
import { useActions } from '~/store/hooks/useActions';

type Props = {
  children?: React.ReactNode;
};

const CheckAuthWrapper: FC<Props> = ({ children }) => {
  const { checkIsUserLoggedIn } = useActions();
  const [isLoading, setIsLoading] = useState(true);

  const load = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await checkIsUserLoggedIn();
      analytics.trackEvent('login - load private page');
    } catch (e: any) {
      console.error('CheckAuthWrapper', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  return isLoading ? <FullscreenPreloader /> : <>{children}</>;
};
export { CheckAuthWrapper };
