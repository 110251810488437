export class FieldsError extends Error {
  public error: string;
  public fields: Record<string, string>;

  constructor(error) {
    super(error.message);
    this.error = error.error;
    this.fields = error.fields;
  }
}

export class ForbiddenError extends Error {
  constructor(error) {
    super(error);
  }
}

export class StreamVendorError extends Error {
  constructor(error) {
    super(error);
  }
}
