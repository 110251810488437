import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import { ReactComponent as AppleIcon } from '~/features/pwa/assets/apple_icon.svg';
import { ReactComponent as JustdoneLogoIcon } from '~/features/pwa/assets/jd_logo.svg';
import i18next from '~/i18n/config';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';

import commonStyles from '../commonStyles.module.scss';

const { t } = i18next;

const bullets = [
  t('modal:pwa_v4.bullets[0]', '10 seconds installation'),
  t('modal:pwa_v4.bullets[1]', 'No authorization'),
  t('modal:pwa_v4.bullets[2]', 'No annoying notifications'),
];

const platform = 'iPhone';

type Props = {
  onSubmit: () => void;
  onDismiss: () => void;
};

const IOS: FC<Props> = ({ onSubmit, onDismiss }) => {
  const { t } = useTranslation('modal');

  return (
    <div className={commonStyles.android_ios_container}>
      <div className={commonStyles.logo_wrapper}>
        <JustdoneLogoIcon />
      </div>

      <Typography
        variant={Typographies.DISPLAY_SMALL}
        className={commonStyles.title}
        component="p"
      >
        <Trans i18nKey="pwa_v4.titles[0]" t={t}>
          Work Faster with <br /> Justdone App for {{ platform }}
        </Trans>
      </Typography>

      <div className={commonStyles.bullets}>
        {bullets.map((el) => (
          <div key={el} className={commonStyles.bullets__item}>
            <Icon
              icon="ic:round-done"
              style={{ color: colors.primary_40 }}
              width="18"
            />

            <Typography
              variant={Typographies.BODY_LARGE}
              className={commonStyles.bullets__item_text}
              component="p"
            >
              {el}
            </Typography>
          </div>
        ))}
      </div>

      <div className={commonStyles.buttons_container}>
        <Button
          className={commonStyles.submit_button}
          color="primary"
          variant="contained"
          onClick={onSubmit}
          fullWidth
        >
          <AppleIcon />
          <span>{t('general:install', 'Install')}</span>
        </Button>

        <Button color="primary" variant="text" onClick={onDismiss} fullWidth>
          {t('general:i_will_do_it_later', 'I will do it later')}
        </Button>
      </div>
    </div>
  );
};

export default IOS;
