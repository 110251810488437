import { AnalyticsItem } from '~/services/analytics/AnalyticManager';

declare global {
  interface Window {
    clarity?: any;
  }
}

class MicrosoftClarity implements AnalyticsItem {
  init(userId: string | null): void {
    if (window.clarity && userId) {
      window.clarity('set', 'user_id', userId);
    }
  }

  trackEvent(eventName: string, parameters?: Record<string, any>): void {
    if (window.clarity) {
      window.clarity('event', eventName, parameters);
    }
  }

  trackPurchase(eventName: string, parameters?: Record<string, any>): void {
    if (window.clarity) {
      window.clarity('event', eventName, parameters);
    }
  }
}

export default MicrosoftClarity;
