import { FORM_FIELDS } from '~/modules/tools/types';

export const AI_DETECTOR_UI_SCHEMA = {
  text: {
    title: 'Text',
    type: FORM_FIELDS.TEXTAREA_UPLOAD,
    max_count: 25000,
    props: {
      required: true,
      placeholder: 'Start typing or paste your text here',
    },
  },
};

export enum DETECTION_STAGE {
  FULLY_HUMAN = 'fully_human',
  MOSTLY_HUMAN = 'mostly_human',
  MEDIUM = 'medium',
  MOSTLY_AI = 'mostly_ai',
  FULLY_AI = 'fully_ai',
}

export const DETECTION_EDGE: Record<DETECTION_STAGE, number> = {
  [DETECTION_STAGE.FULLY_AI]: 100,
  [DETECTION_STAGE.MOSTLY_AI]: 61,
  [DETECTION_STAGE.MEDIUM]: 31,
  [DETECTION_STAGE.MOSTLY_HUMAN]: 1,
  [DETECTION_STAGE.FULLY_HUMAN]: 0,
};
