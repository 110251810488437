import React, { FC, useEffect, useState } from 'react';

import { isEqual } from 'lodash';

import Desktop from '~/components/layouts/MainLayout/Desktop';
import Mobile from '~/components/layouts/MainLayout/Mobile';
import { sidebar, SideBarItemType } from '~/core/sidebar';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import { useAppSelector } from '~/store/hooks/useAppSelector';

type Props = {
  children?: React.ReactNode;
};

const MainLayout: FC<Props> = ({ children }) => {
  const { user_features } = useAppSelector(
    (state) => state.profile,
    (state1, state2) => !isEqual(state1.user_features, state2.user_features),
  );
  const [sidebarGroups, setSidebarGroups] = useState<SideBarItemType[][]>(
    Object.values(sidebar.getAvailableItems(user_features)),
  );
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  useEffect(() => {
    const unsubscribe = sidebar.subscribe(() => {
      setSidebarGroups(Object.values(sidebar.getAvailableItems(user_features)));
    });

    return (): void => {
      unsubscribe();
    };
  }, []);

  const Layout = isDesktop ? Desktop : Mobile;

  return <Layout sidebarGroups={sidebarGroups}>{children}</Layout>;
};

export default MainLayout;
