import React, { FC } from 'react';

import Button from '~/components/atoms/buttons/Button';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
};
const SubscriptionCancelRequiredModal: FC<Props> = ({ onCancel, onSubmit }) => {
  return (
    <div>
      <ModalContentTitle text="Subscription cancellation required" />
      <ModalContentText sx={{ mb: '32px' }}>
        Before deletion of your data you need to cancel your active subscription
      </ModalContentText>
      <div>
        <Button onClick={onCancel} variant="contained">
          Dismiss
        </Button>
        <Button onClick={onSubmit} variant="text">
          Cancel my subscription
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionCancelRequiredModal;
