import React, { FC, useEffect } from 'react';

import { validate as isUuid } from 'uuid';

import Api from '~/Api';
import PaymentForm, {
  paymentFormModalConfig,
} from '~/components/organisms/PaymentForm/views/PaymentForm';
import { useModalContext } from '~/contexts/modal/ModalContext';
import useQueryParams from '~/hooks/useQueryParams';
import analytics from '~/services/analytics';

const CHECKOUT_QUERY_PARAM = 'checkout_product_id';

type Props = {
  children?: React.ReactNode;
};

const CheckoutOpeningMiddleware: FC<Props> = ({ children }) => {
  const { getQueryParams, removeQueryParam } = useQueryParams();
  const queryParams = getQueryParams();
  const { handleOpenModal } = useModalContext();

  useEffect(() => {
    (async (): Promise<void> => {
      if (
        queryParams[CHECKOUT_QUERY_PARAM] &&
        isUuid(queryParams[CHECKOUT_QUERY_PARAM])
      ) {
        try {
          const productById = await Api.getProductById(
            queryParams[CHECKOUT_QUERY_PARAM] as string,
          );

          const resetQueryParams = (): void => {
            removeQueryParam(CHECKOUT_QUERY_PARAM);
          };

          handleOpenModal({
            onClose: () => {
              resetQueryParams();
              analytics.trackEvent('platform checkout - close');
            },
            ...paymentFormModalConfig,
            component: ({ onClose }) => (
              <PaymentForm
                product={productById}
                onSuccessPayment={async (): Promise<void> => {
                  try {
                    resetQueryParams();
                    onClose();
                  } catch (e: any) {
                    console.error(e);
                    resetQueryParams();
                    onClose();
                  }
                }}
              />
            ),
          });
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, []);

  return <>{children}</>;
};

export default CheckoutOpeningMiddleware;
