import timezones from 'compact-timezone-list';
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addWeeks,
  addYears,
  compareAsc,
  compareDesc,
  differenceInDays,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  endOfDay,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isExists,
  isMatch,
  isSameDay,
  isSameSecond,
  isValid,
  isWithinInterval,
  min,
  parse,
  RoundingMethod,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subHours,
  subMinutes,
  toDate,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

enum Days {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export default class DateService {
  static getTimezonesList(): { value: string; text: string }[] {
    //@ts-ignore
    return timezones.map(({ tzCode, label }) => ({
      value: tzCode,
      text: label,
    }));
  }

  static compareAsc(date1: number | Date, date2: number | Date): number {
    return compareAsc(date1, date2);
  }

  static compareDesc(date1: number | Date, date2: number | Date): number {
    return compareDesc(date1, date2);
  }

  static getCurrentDateInSeconds(): number {
    return Math.floor(Date.now() / 1000);
  }

  static isExists(day: number, month: number, year: number): boolean {
    return isExists(year, month, day);
  }

  static differenceInSeconds(
    date1: Date | number,
    date2: Date | number,
    roundingMethod?: RoundingMethod,
  ): number {
    return differenceInSeconds(date1, date2, {
      roundingMethod,
    });
  }

  static format(date: Date | number, formatTemplate: string): string {
    return format(date, formatTemplate);
  }

  static parse(dateString: string, formatTemplate: string): Date {
    try {
      const parsedDate = parse(dateString, formatTemplate, new Date());
      return isValid(parsedDate) ? parsedDate : new Date();
    } catch (e) {
      console.error(e);
      return new Date();
    }
  }

  static isMatch(dateString: string, formatTemplate: string): boolean {
    return isMatch(dateString, formatTemplate);
  }

  static toDate(dateString: number | Date): Date {
    return toDate(dateString);
  }

  static getEndOfDay(date: Date | number): Date {
    return endOfDay(date);
  }

  static getStartOfDay(date: Date | number): Date {
    return startOfDay(date);
  }

  static getStartOfMonth(date: Date | number): Date {
    return startOfMonth(date);
  }

  static getEndOfMonth(date: Date | number): Date {
    return endOfMonth(date);
  }

  static isAfter(date1: Date | number, date2: Date | number): boolean {
    return isAfter(date1, date2);
  }

  static isBefore(date1: Date | number, date2: Date | number): boolean {
    return isBefore(date1, date2);
  }

  static getTimeZone(): string {
    try {
      return window.Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      return 'Europe/Kiev';
    }
  }

  static async getCurrentTime(): Promise<Date> {
    try {
      const getTime = async (): Promise<Date> => {
        const response = await fetch(
          'https://worldtimeapi.org/api/timezone/Europe/Kiev',
        );

        const data = await response.json();

        if (data.utc_datetime) {
          return new Date(data.utc_datetime);
        } else {
          return new Date();
        }
      };

      const fallback = new Promise<Date>((resolve) => {
        window.setTimeout(resolve, 3000, new Date());
      });

      return Promise.race([getTime(), fallback]);
    } catch (e) {
      console.error(e);
      return new Date();
    }
  }

  static differenceInMonths(
    dateLeft: Date | number,
    dateRight: Date | number,
  ): number {
    return differenceInMonths(dateLeft, dateRight);
  }

  static differenceInWeeks(
    dateLeft: Date | number,
    dateRight: Date | number,
  ): number {
    return differenceInWeeks(dateLeft, dateRight);
  }

  static isValid(date: any): boolean {
    return isValid(date);
  }

  static isSameDay(date1: Date | number, date2: Date | number): boolean {
    return isSameDay(date1, date2);
  }

  static isWithinInterval(
    date: Date | number,
    {
      start,
      end,
    }: {
      start: Date | number;
      end: Date | number;
    },
  ): boolean {
    return isWithinInterval(date, { start, end });
  }

  static addHours(date: Date | number, hours: number): Date {
    return addHours(date, hours);
  }

  static subHours(date: Date | number, hours: number): Date {
    return subHours(date, hours);
  }

  static addMinutes(date: Date | number, minutes: number): Date {
    return addMinutes(date, minutes);
  }

  static min(data: (number | Date)[]): Date {
    return min(data);
  }

  static calculateAge(birthday: string, format: string): number {
    const date = parse(birthday, format, new Date());
    return differenceInYears(new Date(), date);
  }

  static formatCurrentTimeInTimeZone(timezone: string, format: string): string {
    const date = new Date();
    return formatInTimeZone(date, timezone, format);
  }

  static addDays(date: Date | number, amount: number): Date {
    return addDays(date, amount);
  }

  static subDays(date: Date | number, amount: number): Date {
    return subDays(date, amount);
  }

  static addWeeks(date: Date | number, amount: number): Date {
    return addWeeks(date, amount);
  }

  static addMonths(date: Date | number, amount: number): Date {
    return addMonths(date, amount);
  }

  static addYears(date: Date | number, amount: number): Date {
    return addYears(date, amount);
  }

  static differenceInDays(date1: Date | number, date2: Date | number): number {
    return differenceInDays(date1, date2);
  }

  static differenceInMinutes(
    date1: Date | number,
    date2: Date | number,
  ): number {
    return differenceInMinutes(date1, date2);
  }

  static getFirstWeekDayNumber(): 1 | 2 | 3 | 4 | 5 | 6 | 7 {
    try {
      // @ts-ignore
      const number = new Intl.Locale(navigator.language).weekInfo.firstDay;
      return number as 1 | 2 | 3 | 4 | 5 | 6 | 7; // 1 - monday
    } catch (e) {
      return 1;
    }
  }

  static formatTimeInCurrentLocale(date: Date): string {
    try {
      const dtf = new Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
      });
      return dtf.format(date);
    } catch (e) {
      return DateService.format(date, 'p');
    }
  }

  static formatDateInCurrentLocale(date: Date): string {
    try {
      const dtf = new Intl.DateTimeFormat(navigator.language);
      return dtf.format(date);
    } catch (e) {
      return date.toLocaleDateString();
    }
  }

  static getWeekDaysInCurrentLocale(): Days[] {
    const day = DateService.getFirstWeekDayNumber();
    const firstDOW = startOfWeek(new Date(), {
      weekStartsOn: day === 7 ? 0 : day,
    });
    return Array.from(Array(7)).map(
      (_, i) => format(addDays(firstDOW, i), 'EEEE') as Days,
    );
  }

  static getCurrentTimezoneOffset(): number {
    const currentDate = new Date();
    const localizedTime = new Date(currentDate.toLocaleString('en-US'));
    const utcTime = new Date(
      currentDate.toLocaleString('en-US', { timeZone: 'UTC' }),
    );
    return (localizedTime.getTime() - utcTime.getTime()) / (60 * 60 * 1000);
  }

  static getFormattedTimezoneOffset(
    timeZone: string,
    atTime: Date = new Date(),
  ): string | null {
    try {
      const localizedTime = new Date(
        atTime.toLocaleString('en-US', { timeZone }),
      );
      const utcTime = new Date(
        atTime.toLocaleString('en-US', { timeZone: 'UTC' }),
      );
      const offset =
        (localizedTime.getTime() - utcTime.getTime()) / (60 * 60 * 1000);
      return offset >= 0 ? `+${offset}` : `-${Math.abs(offset).toString()}`;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static sameOrBefore = (d1: Date | number, d2: Date | number): boolean => {
    return isSameSecond(d1, d2) ? true : isBefore(d1, d2);
  };

  static sameOrAfter = (d1: Date | number, d2: Date | number): boolean => {
    return isSameSecond(d1, d2) ? true : isAfter(d1, d2);
  };

  static isRangeBetween(
    date: Date,
    [from, to]: [Date, Date],
    equalCounts = true,
  ): boolean {
    return equalCounts
      ? DateService.sameOrBefore(date, from) &&
          DateService.sameOrAfter(date, to)
      : isBefore(date, from) && isAfter(date, to);
  }

  static setTime(date: Date, hours: number, minutes = 0, seconds = 0): Date {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes,
      seconds,
    );
  }

  static sort(
    date: Date[] | number[],
    order: 'asc' | 'desc',
  ): Date[] | number[] {
    return date.sort((a, b) =>
      order === 'asc' ? compareAsc(a, b) : compareDesc(a, b),
    );
  }

  static subMinutes(date: Date | number, amount: number): Date {
    return subMinutes(date, amount);
  }
}
