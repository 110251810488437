import { createTheme, PaletteMode, Theme } from '@mui/material';

import { colors } from '~/theme/colors';
import { getPalette } from '~/theme/palette';
import { fonts, typography } from '~/theme/typography';

export const createCustomTheme = (mode: PaletteMode): Theme => {
  return createTheme({
    palette: getPalette(mode),
    colors,
    spacing: [0, 4, 8, 16, 32, 40, 48],
    typography: {
      ...typography,
      fontFamily: fonts.default,
    },
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: '16px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
            marginLeft: 4,
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 560,
        md: 992,
        lg: 1280,
        xl: 1440,
        xxl: 1920,
      },
    },
  });
};

export default createCustomTheme;
