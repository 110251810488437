import React, { FC, useEffect } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import UpgradeImg from '~/assets/images/upgrade.webp';
import UpgradePreviewImg from '~/assets/images/upgrade_preview.png';
import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';
import { LimitsRenewPeriod } from '~/types/common';

import styles from './styles.module.scss';

const periodAmountOfWords = {
  [LimitsRenewPeriod.WEEK]: '20,000',
  [LimitsRenewPeriod.MONTH]: '80,000',
};

const DEFAULT_WEEKLY_BENEFITS = (amount: string): JSX.Element[] => {
  return [
    <>
      <Trans
        i18nKey="modal:subscription_details.benefits[0]"
        values={{ amount: amount }}
      >
        <span>{'{{amount}} words'}</span> in AI Content Detector
      </Trans>
    </>,
    <>
      <Trans
        i18nKey="modal:subscription_details.benefits[1]"
        values={{ amount: amount }}
      >
        <span>{'{{amount}} words'}</span> in AI Text Humanizer
      </Trans>
    </>,
    <>
      <Trans
        i18nKey="modal:subscription_details.benefits[2]"
        values={{ amount: amount }}
      >
        <span>{'{{amount}} words'}</span> in Check Plagiarism tool
      </Trans>
    </>,
  ];
};

const DEFAULT_DAILY_BENEFITS = [
  <>
    <Trans i18nKey="modal:subscription_details.benefits[3]">
      Generate <span>20 images</span>
    </Trans>
  </>,
  <>
    <Trans i18nKey="modal:subscription_details.benefits[4]">
      Improve <span>20 prompts</span>
    </Trans>
  </>,
];

type Props = {
  onClose: () => void;
  planDetails: {
    name: string;
  };
};

const SubscriptionDetailsModal: FC<Props> = ({ onClose, planDetails }) => {
  const { t } = useTranslation('modal');

  useEffect(() => {
    analytics.trackEvent('subscription - rebill benefits modal show');
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image src={UpgradeImg} preview={UpgradePreviewImg} />
      </div>

      <Typography
        className={styles.title}
        variant={Typographies.TITLE_LARGE}
        component="h3"
      >
        {t('subscription_details.title', {
          plan_name: planDetails.name,
          defaultValue: 'You are now on {{- plan_name}}!',
        })}
      </Typography>

      <div>
        <div className={styles.list}>
          <Typography
            className={styles.list__title}
            variant={Typographies.TITLE_MEDIUM}
            component="p"
          >
            {t('subscription_details.list_titles[0]', {
              period: LimitsRenewPeriod.MONTH,
              defaultValue: 'Here is what you get every {{period}}:',
            })}
          </Typography>

          {DEFAULT_WEEKLY_BENEFITS(
            periodAmountOfWords[LimitsRenewPeriod.MONTH],
          ).map((item, idx) => (
            <div key={idx} className={styles.list__item}>
              <Icon
                icon="material-symbols:check"
                className={styles.list__item_icon}
              />

              <Typography
                className={styles.list__item_text}
                variant={Typographies.TITLE_MEDIUM}
                component="p"
              >
                {item}
              </Typography>
            </div>
          ))}
        </div>

        <div className={styles.list}>
          <Typography
            className={styles.list__title}
            variant={Typographies.TITLE_MEDIUM}
            component="p"
          >
            {t('subscription_details.list_titles[1]', 'And each day, you can:')}
          </Typography>

          {DEFAULT_DAILY_BENEFITS.map((item, idx) => (
            <div key={idx} className={styles.list__item}>
              <Icon
                icon="material-symbols:check"
                className={styles.list__item_icon}
              />

              <Typography
                className={styles.list__item_text}
                variant={Typographies.TITLE_MEDIUM}
                component="p"
              >
                {item}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={onClose}
        className={styles.button}
      >
        {t('general:got_it', 'Got It')}
      </Button>
    </div>
  );
};

export default SubscriptionDetailsModal;
