import React, { FC, useState } from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';

type Props = {
  src: string;
  preview: string;
  alt?: string;
  className?: string;
  onLoad?: () => void;
};

const Image: FC<Props> = ({
  src,
  preview,
  className,
  alt = 'justdone image',
  onLoad,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className={clsx(styles.image__wrapper, className)}>
      <img
        className={styles.image__main}
        src={src}
        onLoad={(): void => {
          setIsLoaded(true);
          onLoad && onLoad();
        }}
        alt={alt}
      />

      <img
        className={clsx(styles.image__preview, {
          [styles.is_hidden]: isLoaded,
        })}
        src={preview}
        alt={`${alt} preview`}
      />
    </div>
  );
};

export default Image;
