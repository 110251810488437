import React, {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { styled } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import Api from '~/Api';
import useErrors from '~/hooks/useErrors';

import { formOptions } from './constants';
import FeedbackStep from './FeedbackStep';
import ReasonStep from './ReasonStep';
import styles from './styles.module.scss';
import { CancellationFormDataType, FormFields } from '../../types';

const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
}));

export enum FORM_STEP {
  REASON = 'reason',
  FEEDBACK = 'feedback',
}

const DEFAULT_FEEDBACK_TITLE = 'Tell us more about your experience.';

const initialFormState = {
  [FormFields.REASON]: '',
  [FormFields.SUB_REASON]: '',
  [FormFields.RELATED_TO]: '',
  [FormFields.FEEDBACK]: '',
};

type Props = {
  title: string;
  onFormSubmit: (data: CancellationFormDataType) => Promise<void>;
  onFormRefuse: () => void;
};

const FormMultiple: FC<Props> = ({ title, onFormSubmit, onFormRefuse }) => {
  const [form, setForm] = useState<CancellationFormDataType>(initialFormState);

  const [options, setOptions] = useState(formOptions);
  const [currentStep, setCurrentStep] = useState(FORM_STEP.REASON);
  const scrollElemRef = useRef<HTMLDivElement | null>(null);
  const { reportUserErrors } = useErrors();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const { user_popular_tool_name, user_last_tool_name } =
          await Api.getUsageStatistics();

        const relations = [
          ...(user_last_tool_name &&
          user_last_tool_name !== user_popular_tool_name
            ? [user_last_tool_name]
            : []),
          ...(user_popular_tool_name ? [user_popular_tool_name] : []),
        ];

        if (relations.length > 0) {
          setOptions((prev) => ({
            ...prev,
            relations: [...relations, 'Other'],
          }));
        }
      } catch (e: any) {
        reportUserErrors({
          error: e,
          method: 'getUsageStatistics',
          userMessage:
            'Usage statistics are currently unavailable. Please try later or',
        });
      }
    })();
  }, []);

  const handleGoToNextStep = (
    data: Partial<CancellationFormDataType>,
    step: FORM_STEP,
  ): void => {
    setForm((prev) => ({ ...prev, ...data }));
    setCurrentStep(step);

    scrollElemRef.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  const STEPS: Record<FORM_STEP, ReactElement<any, any>> = useMemo(() => {
    return {
      [FORM_STEP.REASON]: (
        <ReasonStep
          title={title}
          reasons={options.reasons}
          subReasons={options.sub_reasons}
          relations={options.relations}
          onRefuse={onFormRefuse}
          onSubmit={(data): void =>
            handleGoToNextStep(data, FORM_STEP.FEEDBACK)
          }
        />
      ),
      [FORM_STEP.FEEDBACK]: (
        <FeedbackStep
          title={options.feedbacks[form.reason] || DEFAULT_FEEDBACK_TITLE}
          onRefuse={onFormRefuse}
          onSubmit={(data): Promise<void> =>
            onFormSubmit({
              ...form,
              [FormFields.FEEDBACK]: data.feedback,
            })
          }
        />
      ),
    };
  }, [form, options]);

  return (
    <div className={styles.container} ref={scrollElemRef}>
      <StyledFormControl error={!form.reason}>
        {STEPS[currentStep]}
      </StyledFormControl>
    </div>
  );
};

export default FormMultiple;
