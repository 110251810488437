import { flowRight } from 'lodash';

import router, { RouteItemType } from '~/core/router';
import {
  withMainSimpleLayout,
  withOnboardingLayout,
} from '~/decorators/withLayout';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import {
  CancellationPath,
  CancellationTitle,
} from '~/modules/cancellation/constants';

const Cancellation = lazyComponentLoader(() => import('./views'));

const CancellationRoute: RouteItemType = {
  Component: flowRight(
    withOnboardingLayout,
    withMainSimpleLayout,
  )(Cancellation),
  path: CancellationPath,
  title: CancellationTitle,
};

router.addPrivateRoutes([CancellationRoute]);
