import { PayableEntity } from '@solidgate/react-sdk';

export type AnalyticPaymentMethods =
  | 'card'
  | 'paypal'
  | 'apple_pay'
  | 'google_pay';

// @ts-ignore
export const SOLID_PAYMENT_METHOD_MAPPER: Record<
  PayableEntity,
  AnalyticPaymentMethods
> = {
  [PayableEntity.Form]: 'card',
  [PayableEntity.ApplePay]: 'apple_pay',
  [PayableEntity.GooglePay]: 'google_pay',
};

export enum PAYMENT_METHODS {
  CARD = 'card',
  ONE_PAY = 'one_click',
  PAYPAL = 'paypal',
}

export interface MerchantData {
  paymentIntent: string;
  merchant: string;
  signature: string;
}
