import React from 'react';

import { Icon } from '@iconify/react';

import PlusImg from '~/assets/images/plus.webp';
import { PRODUCT } from '~/constants/products';
import i18next from '~/i18n/config';
import { LimitsRenewPeriod } from '~/types/common';

import { ReactComponent as ContentDetector } from './content_detector.svg';
import styles from './styles.module.scss';

import { OrderDescriptionType } from './index';

const { t } = i18next;

const listItemValuesByPeriod = {
  [LimitsRenewPeriod.WEEK]: t(
    'subscription:payment_form.order_details_onetime_detectors[1]',
    {
      context: 'week',
      defaultValue: 'It will not affect the weekly limit',
    },
  ),
  [LimitsRenewPeriod.MONTH]: t(
    'subscription:payment_form.order_details_onetime_detectors[1]',
    {
      context: 'month',
      defaultValue: 'It will not affect the monthly limit',
    },
  ),
};

const onetimeDetectorsDescriptions = (
  wordCount: number,
  renewPeriod: LimitsRenewPeriod,
): {
  details_list_ui: JSX.Element[];
  image_src_ui: string;
} => {
  return {
    details_list_ui: [
      <>
        <Icon icon="foundation:check" className={styles.detail__icon} />
        <span>
          {wordCount.toLocaleString()} {t('general:words')}
        </span>
      </>,
      <>
        <Icon icon="foundation:check" className={styles.detail__icon} />
        <span>
          {t(
            'subscription:payment_form.order_details_onetime_detectors[0]',
            'Extra words is a one-time purchase',
          )}
        </span>
      </>,
      <>
        <Icon icon="foundation:check" className={styles.detail__icon} />
        <span>{listItemValuesByPeriod[renewPeriod]}</span>
      </>,
    ],
    image_src_ui: PlusImg,
  };
};

// TODO! 937. Add new id's
// We don't use stripe id's since we have a default(not custom) stripe checkout
export const getExtraProductDescriptions = (
  productId: string,
  renewPeriod: LimitsRenewPeriod,
): OrderDescriptionType | null => {
  switch (productId) {
    case PRODUCT.ONETIME_DETECTORS_10_DOLLARS_10K_WORDS:
      return onetimeDetectorsDescriptions(10000, renewPeriod);

    case PRODUCT.ONETIME_DETECTORS_20_DOLLARS_20K_WORDS:
      return onetimeDetectorsDescriptions(20000, renewPeriod);

    case PRODUCT.ONETIME_DETECTORS_40_DOLLARS_50K_WORDS:
      return onetimeDetectorsDescriptions(50000, renewPeriod);

    case PRODUCT.ONETIME_DETECTORS_80_DOLLARS_100K_WORDS:
      return onetimeDetectorsDescriptions(100000, renewPeriod);

    case PRODUCT.ONETIME_DETECTORS_100_DOLLARS_200K_WORDS:
      return onetimeDetectorsDescriptions(200000, renewPeriod);

    case PRODUCT.UNBLOCK_DETECTOR_TOOLS_10_DOLLARS:
    case PRODUCT.UNBLOCK_DETECTOR_TOOLS_7_5_DOLLARS:
      return {
        details_list_ui: [
          <>
            <Icon
              icon="material-symbols:quick-reference-all-outline-rounded"
              className={styles.detail__paraphrase_icon}
            />
            <span>
              {t(
                'subscription:payment_form.order_details_unblock_detectors[0]',
                'Plagiarism Level Scanner',
              )}
            </span>
          </>,
          <>
            <ContentDetector />
            <span>
              {t(
                'subscription:payment_form.order_details_unblock_detectors[1]',
                'AI Content Detector',
              )}
            </span>
          </>,
          <>
            <Icon
              icon="material-symbols:frame-person"
              className={styles.detail__paraphrase_icon}
            />
            <span>
              {t(
                'subscription:payment_form.order_details_unblock_detectors[2]',
                'AI Text Humanizer',
              )}
            </span>
          </>,
        ],
        image_src_ui: PlusImg,
      };

    default:
      return null;
  }
};
