import React, { FC } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';

import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  children?: React.ReactNode;
  className?: string;
  variant?: Typographies;
  sx?: Record<string, any>;
};

const ModalContentText: FC<Props> = ({ children, className, ...props }) => {
  return (
    <Typography
      className={clsx(styles.text, className)}
      variant={props.variant ? props.variant : Typographies.TITLE_MEDIUM}
      component="p"
      {...props}
    >
      {children}
    </Typography>
  );
};

export default ModalContentText;
