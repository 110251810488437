import { REGULARITY } from '~/types/product';

export enum SUBSCRIPTION_STATUS {
  ACTIVE = 'active',
  REDEMPTION = 'redemption',
  REDEMPTION_PAYMENT_PENDING = 'redemption_payment_pending',
  REDEMPTION_PAYMENT_FAILED = 'redemption_payment_failed',
  CANCELLED = 'cancelled',
  CANCEL_PENDING = 'cancel_pending',
  PAUSED = 'paused',
  PAUSE_PENDING = 'pause_pending',
}

export interface SubscriptionType {
  id: string;
  is_unlimited: boolean;
  billing_period: REGULARITY;
  product_id: string;
  product_tags: string[];
  subscription_price: number;
  subscription_name: string;
  status: SUBSCRIPTION_STATUS;
  started_at: string;
  expired_at: string | null;
  next_charge_at: string | null;
  cancelled_at: string | null;
  is_trial: boolean;
  cancel_code: string | null;
  cancel_message: string | null;
  payment_type: 'card' | 'paypal-vault'; // TODO! We should find a correct type for apple_pay
  pause_from: string | null;
  pause_to: string | null;
  created_at: string;
  updated_at: string;
  words_amount_left: number;
  words_amount_total: number;
  words_generated_total: number;
  saved_time_minutes: number;
  response_count_total: number;
}
