import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

const LOCIZE_API_KEY = process.env.LOCIZE_API_KEY || '';

// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    supportedLngs: ['en', 'es'],
    // debug: true,
    saveMissing: true,
    fallbackLng: 'en',
    nsSeparator: ':',
    // parseMissingKeyHandler(key: string, defaultValue?: string): any {
    //   if (!defaultValue) {
    //     const splitedKey = key.split('.');
    //     return splitedKey[splitedKey.length - 1];
    //   }
    // },
    defaultNS: 'general',
    backend: {
      projectId: '26ec31a0-0bb4-4791-80e1-c5bdd1074835',
      apiKey: LOCIZE_API_KEY,
      referenceLng: 'en',
      allowedAddOrUpdateHosts: [
        'localhost',
        'stage-app.justdone.ai',
        'stage2-app.justdone.ai',
      ],
    },
  });

export default i18next;
