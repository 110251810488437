import router, { RouteItemType } from '~/core/router';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import {
  ForgotPasswordPath,
  ForgotPasswordTitle,
  ResetPasswordPath,
  ResetPasswordTitle,
  SignInPath,
  SignInTitle,
  SignUpPath,
  SignUpTitle,
} from '~/modules/auth/constants';

const SignIn = lazyComponentLoader(() => import('./components/SignIn'));
const SignUp = lazyComponentLoader(() => import('./components/SignUp'));
const ForgotPassword = lazyComponentLoader(
  () => import('./components/ForgotPassword'),
);
const ResetPassword = lazyComponentLoader(
  () => import('./components/ResetPassword'),
);

const SignInRoute: RouteItemType = {
  Component: SignIn,
  path: SignInPath,
  title: SignInTitle,
};

const SignUpRoute: RouteItemType = {
  Component: SignUp,
  path: SignUpPath,
  title: SignUpTitle,
};
const ForgotPasswordRoute: RouteItemType = {
  Component: ForgotPassword,
  path: ForgotPasswordPath,
  title: ForgotPasswordTitle,
};

const ResetPasswordRoute: RouteItemType = {
  Component: ResetPassword,
  path: ResetPasswordPath,
  title: ResetPasswordTitle,
};

router.addAuthRoutes([
  SignInRoute,
  SignUpRoute,
  ForgotPasswordRoute,
  ResetPasswordRoute,
]);
