// this solution better than matchPath from react-router-dom

export const checkIsRouteMatch = (
  path: string,
  currentPath: string,
): boolean => {
  try {
    const routeMatcher = new RegExp(path.replace(/:[^\s/]+/g, '([\\w-]+)'));
    const match = currentPath.match(routeMatcher);
    if (!match) {
      return false;
    }

    return match.input === currentPath;
  } catch (e) {
    return false;
  }
};
