import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { HIGHLIGHT } from '~/modules/highlight/constants';

type ContextStateType = {
  addHighLights(highlights: HIGHLIGHT[]): void;
  currentHighlight: HIGHLIGHT | null;
  clearCurrentHighLight(): void;
};

const HighlightContext = createContext<ContextStateType>({
  addHighLights: () => {},
  currentHighlight: null,
  clearCurrentHighLight: () => {},
});

type HighlightContextProviderProps = {
  children?: React.ReactNode;
};

const HighlightContextProvider: React.FC<HighlightContextProviderProps> = ({
  children,
}) => {
  const [highlights, setHighlights] = useState<HIGHLIGHT[]>([]);

  const addHighLights = useCallback(
    (keys: HIGHLIGHT[]): void =>
      setHighlights((prev) => {
        const newItems = [...prev];
        keys.forEach((key) => {
          if (!newItems.includes(key)) {
            newItems.push(key);
          }
        });
        return newItems.length ? newItems : prev;
      }),
    [],
  );

  const clearCurrentHighLight = useCallback(
    (): void =>
      setHighlights((prev) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...rest] = prev;
        return rest;
      }),
    [],
  );

  const currentHighlight = useMemo(() => {
    return highlights[0] || null;
  }, [highlights.length]);

  return (
    <HighlightContext.Provider
      value={{
        addHighLights,
        currentHighlight,
        clearCurrentHighLight,
      }}
    >
      {children}
    </HighlightContext.Provider>
  );
};

const useHighlightContextProvider = (): ContextStateType => {
  const highlightContext = useContext<ContextStateType>(HighlightContext);

  if (!highlightContext) {
    throw Error(
      'useHighlightContextProvider hook should be wrapped by HighlightContextProvider',
    );
  }

  return highlightContext;
};

export { HighlightContextProvider, useHighlightContextProvider };
