export enum AB_TESTS_KEYS {
  PWA_COMMUNICATION_V4_1 = 'pwa-v4.1-re-test',
  CHAT_ONBOARDING = 'chat_onboarding',
  SUB_SKIP_TRIAL = 'subscription_skip_trial',
  EXTRA_WORDS_DISCOUNT_SYSTEM = 'extra-words-discount-system',
}

export const AB_TEST_FEATURES: Record<
  AB_TESTS_KEYS,
  {
    key: AB_TESTS_KEYS;
    variants: {
      control: string;
      variant_1: string;
      [key: string]: string;
    };
    defaultVariant: string;
  }
> = {
  [AB_TESTS_KEYS.PWA_COMMUNICATION_V4_1]: {
    key: AB_TESTS_KEYS.PWA_COMMUNICATION_V4_1,
    variants: {
      control: '4.1',
      variant_1: '4.0',
    },
    defaultVariant: '4.1',
  },
  [AB_TESTS_KEYS.CHAT_ONBOARDING]: {
    key: AB_TESTS_KEYS.CHAT_ONBOARDING,
    variants: {
      control: 'control',
      variant_1: 'tested',
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.EXTRA_WORDS_DISCOUNT_SYSTEM]: {
    key: AB_TESTS_KEYS.EXTRA_WORDS_DISCOUNT_SYSTEM,
    variants: {
      control: 'default',
      variant_1: 'discounts',
    },
    defaultVariant: 'default',
  },
  [AB_TESTS_KEYS.SUB_SKIP_TRIAL]: {
    key: AB_TESTS_KEYS.SUB_SKIP_TRIAL,
    variants: {
      control: 'unlock_detectors',
      variant_1: 'skip_trial',
      variant_2: 'both_variants',
    },
    defaultVariant: 'unlock_detectors',
  },
};
