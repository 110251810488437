import React, {
  createContext,
  MutableRefObject,
  useCallback,
  useContext,
  useState,
} from 'react';

import Popover from '~/components/atoms/Popover';
import useVisualViewportResize from '~/modules/chat/hooks/useVisualViewportResize';

type Position = {
  vertical: 'top' | 'center' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
};
const getTransformOrigin = (position: Position): Position => {
  const reversedPosition = {
    vertical:
      position.vertical === 'top'
        ? 'bottom'
        : position.vertical === 'bottom'
          ? 'top'
          : 'center',
    horizontal:
      position.horizontal === 'left'
        ? 'right'
        : position.horizontal === 'right'
          ? 'left'
          : 'center',
  };

  return reversedPosition as Position;
};

interface Props {
  component: React.FC<{ onClose: () => void }>;
  anchorRef?: MutableRefObject<HTMLElement | null>;
  position?: Position;
  disableBackdropClick?: boolean;
  onClose?: () => void;
  onShow?: () => void;
  className?: string;
}

type ContextStateType = {
  handleOpenPopover(data: Props): void;
};

type PopoverStateType = {
  isOpen: boolean;
  anchorRef?: MutableRefObject<HTMLElement | null>;
  position?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
  component: React.FC<{ onClose: () => void }>;
  disableBackdropClick: boolean;
  onClose: () => void;
  onShow: () => void;
  className?: string;
};

const initialPopoverState: PopoverStateType = {
  isOpen: false,
  component: () => <div />,
  disableBackdropClick: false,
  onClose: () => {},
  onShow: () => {},
};

const PopoverContext = createContext<ContextStateType>({
  handleOpenPopover: () => {},
});

type PopoverContextProviderProps = {
  children?: React.ReactNode;
};

const PopoverContextProvider: React.FC<PopoverContextProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState<PopoverStateType>(initialPopoverState);
  const isResized = useVisualViewportResize();

  const handleOpenPopover = useCallback(
    ({
      anchorRef,
      position = {
        vertical: 'top',
        horizontal: 'center',
      },
      component,
      onClose = (): void => {},
      onShow = (): void => {},
      disableBackdropClick = false,
      className,
    }: Props): void => {
      setState(() => {
        onShow();
        return {
          anchorRef,
          position,
          isOpen: true,
          component,
          onClose,
          onShow,
          disableBackdropClick,
          className,
        };
      });
    },
    [],
  );

  const handleClose = (): void => {
    setState((prevState) => ({ ...prevState, isOpen: false }));
  };

  return (
    <PopoverContext.Provider
      value={{
        handleOpenPopover,
      }}
    >
      {children}
      {!isResized && (
        <Popover
          anchorEl={state?.anchorRef?.current}
          open={state.isOpen}
          anchorOrigin={state.position}
          transformOrigin={state.position && getTransformOrigin(state.position)}
          className={state.className}
          onClose={(): void => {
            if (!state.disableBackdropClick) {
              state.onClose();
              handleClose();
            }
          }}
        >
          {state.component({
            onClose: handleClose,
          })}
        </Popover>
      )}
    </PopoverContext.Provider>
  );
};

const usePopoverContext = (): ContextStateType => {
  const popoverContext = useContext<ContextStateType>(PopoverContext);

  if (!popoverContext) {
    throw Error(
      'usePopoverContext hook should be wrapped by PopoverContextProvider',
    );
  }

  return popoverContext;
};

export { PopoverContextProvider, usePopoverContext };
