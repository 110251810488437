import React, { FC } from 'react';

import SidebarDesktop from '~/components/layouts/MainLayout/Sidebar/desktop';
import { SideBarItemType } from '~/core/sidebar';

import styles from './styles.module.scss';

type Props = {
  sidebarGroups: SideBarItemType[][];
  children?: React.ReactNode;
};

const DesktopLayoutSimple: FC<Props> = ({ sidebarGroups, children }) => {
  return (
    <div className={styles.layout}>
      <div className={styles.layout__sidebar}>
        <SidebarDesktop items={sidebarGroups} />
      </div>

      <div className={styles.layout__content}>{children}</div>
    </div>
  );
};

export default DesktopLayoutSimple;
