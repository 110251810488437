import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import MacChromeAddressImg from '~/features/pwa/assets/v4_windows_mac_chrome_address_bar.webp';
import MacChromeAddressPreviewImg from '~/features/pwa/assets/v4_windows_mac_chrome_address_bar_preview.png';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  onSubmit: () => void;
};

const ChromeDesktop: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation('modal');

  return (
    <div className={styles.container}>
      <div className={styles.steps}>
        <div className={styles.steps__item}>
          <Typography
            variant={Typographies.TITLE_MEDIUM}
            className={styles.steps__item_title}
            component="h4"
          >
            <span>1.</span>
            <span>
              {t(
                'pwa_v4.chrome_desktop_steps[0]',
                'Click this icon in the address bar at the top',
              )}
            </span>
          </Typography>

          <div className={styles.steps__item_image}>
            <Image
              src={MacChromeAddressImg}
              preview={MacChromeAddressPreviewImg}
            />
          </div>
        </div>

        <div className={styles.steps__item}>
          <Typography
            variant={Typographies.TITLE_MEDIUM}
            className={styles.steps__item_title}
            component="h4"
          >
            <span>2.</span>
            <span>
              {t('pwa_v4.chrome_desktop_steps[1]', 'Click “Install”')}
            </span>
          </Typography>
        </div>
      </div>

      <Button
        color="primary"
        variant="text"
        onClick={onSubmit}
        className={styles.submit_button}
      >
        <Icon
          icon="ic:round-done"
          style={{ color: colors.primary_40 }}
          width="18"
        />
        <span>{t('general:done', 'Done')}</span>
      </Button>
    </div>
  );
};

export default ChromeDesktop;
