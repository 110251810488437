import React, { createContext, useCallback, useContext, useState } from 'react';

import Modal from '~/components/molecules/Modal/Modal';
import { colors } from '~/theme/colors';

export type ModalStateType = {
  isOpen: boolean;
  component: React.FC<{ onClose: () => void }>;
  closeButtonColor?: string;
  hideCloseButton?: boolean;
  onClose?: () => void;
  onShow?: () => void;
  disableBackdropClick?: boolean;
  maxWidth?: string;
  isFullScreen?: boolean;
  paddingSize?: string;
  pageViewMobile?: boolean;
  position?: {
    horizontal: 'left' | 'center' | 'right';
    vertical: 'top' | 'center' | 'bottom';
  };
  hideBackdrop?: boolean;
  paperStyles?: React.CSSProperties;
};

type ContextStateType = {
  handleOpenModal(data: Omit<ModalStateType, 'isOpen'>): void;
};

const initialModalState: ModalStateType = {
  isOpen: false,
  component: () => <div />,
};

const ModalContext = createContext<ContextStateType>({
  handleOpenModal: () => {},
});

type ModalContextProviderProps = {
  children?: React.ReactNode;
};

const ModalContextProvider: React.FC<ModalContextProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState<ModalStateType>(initialModalState);

  const handleOpenModal = useCallback(
    ({
      component,
      closeButtonColor = colors.black,
      hideCloseButton = false,
      onClose = (): void => {},
      onShow = (): void => {},
      disableBackdropClick = false,
      pageViewMobile = true,
      maxWidth = '460px',
      isFullScreen = false,
      paddingSize = '24px',
      position = { horizontal: 'center', vertical: 'center' },
      hideBackdrop,
      paperStyles,
    }: Omit<ModalStateType, 'isOpen'>): void => {
      setState(() => {
        onShow();
        return {
          isOpen: true,
          component,
          closeButtonColor,
          onClose,
          onShow,
          hideCloseButton,
          disableBackdropClick,
          pageViewMobile,
          maxWidth,
          isFullScreen,
          paddingSize,
          position,
          hideBackdrop,
          paperStyles,
        };
      });
    },
    [],
  );

  const handleClose = (): void => {
    setState((prevState) => ({ ...prevState, isOpen: false }));
  };

  return (
    <ModalContext.Provider
      value={{
        handleOpenModal,
      }}
    >
      {children}
      <Modal
        isOpen={state.isOpen}
        closeButtonColor={state.closeButtonColor}
        onClose={(): void => {
          state.onClose && state.onClose();
          handleClose();
        }}
        hideCloseButton={state.hideCloseButton}
        pageViewMobile={state.pageViewMobile}
        maxWidth={state.maxWidth}
        isFullScreen={state.isFullScreen}
        paddingSize={state.paddingSize}
        position={state.position}
        disableBackdropClick={state.disableBackdropClick}
        hideBackdrop={state.hideBackdrop}
        paperStyles={state.paperStyles}
      >
        {state.component({
          onClose: () => {
            state.onClose && state.onClose();
            handleClose();
          },
        })}
      </Modal>
    </ModalContext.Provider>
  );
};

const useModalContext = (): ContextStateType => {
  const modalContext = useContext<ContextStateType>(ModalContext);

  if (!modalContext) {
    throw Error(
      'useModalContext hook should be wrapped by ModalContextProvider',
    );
  }

  return modalContext;
};

export { ModalContextProvider, useModalContext };
