import {
  DETECTION_EDGE,
  DETECTION_STAGE,
} from '~/modules/tools/views/variant2/AiDetectorV2/constants';

export const getDetectionStage = (result: number): DETECTION_STAGE => {
  const stage = Object.entries(DETECTION_EDGE)
    .sort(([, a], [, b]) => b - a)
    .find(([, value]) => result >= value);
  return stage ? (stage[0] as DETECTION_STAGE) : DETECTION_STAGE.FULLY_HUMAN;
};

export const getTextSpecialSymbols = (text: string): string[] => {
  const regexp = /[*#]/g;
  const matches = text.match(regexp);

  if (!matches) {
    return [];
  }

  return [...new Set(matches)]; // Remove duplicates by converting to a Set
};
