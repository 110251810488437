import React, { FC, useEffect, useState } from 'react';

import FullscreenPreloader from '~/components/atoms/FullscreenPreloader';
import { growthBook } from '~/services/abTests/growthBook';
import { useAppSelector } from '~/store/hooks/useAppSelector';

type Props = {
  children: React.ReactNode;
};

const GrowthBookProvider: FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useAppSelector((state) => state.profile);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        if (id) {
          await growthBook.init({
            //place for setting extra attributes
            id,
          });

          growthBook.startAbTests();
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  return <>{isLoading ? FullscreenPreloader : children}</>;
};

export default GrowthBookProvider;
