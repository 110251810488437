import React, { FC } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import router from '~/core/router';
import AuthRoute from '~/route/AuthRoute';
import PrivateRoute from '~/route/PrivateRoute';
import UnAuthorizedRoute from '~/route/UnAuthorizedRoute';

const authRoutes = router.getAuthRoutes();
const privateRoutes = router.getPrivateRoutes();
const unAuthorizedRoutes = router.getUnAuthorizedRoutes();

const Router: FC = () => {
  return (
    <Routes>
      {authRoutes.map(({ path, Component }) => (
        <Route
          key={path}
          path={path}
          element={<AuthRoute Component={Component} />}
        />
      ))}
      {privateRoutes.map(({ path, Component, checkAvailability }) => (
        <Route
          key={path}
          path={path}
          element={
            <PrivateRoute
              Component={Component}
              checkAvailability={checkAvailability}
            />
          }
        />
      ))}
      {unAuthorizedRoutes.map(({ path, Component, checkAvailability }) => (
        <Route
          key={path}
          path={path}
          element={
            <UnAuthorizedRoute
              Component={Component}
              checkAvailability={checkAvailability}
            />
          }
        />
      ))}
      <Route path="*" element={<Navigate to={router.getSignInRoutePath()} />} />
    </Routes>
  );
};

export default Router;
