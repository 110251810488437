import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';

import Button from '~/components/atoms/buttons/Button';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import ViewAllPlansLink from '~/modules/settings/components/modals/ChooseSubscriptionModal/elements/ViewAllPlansLink';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';
import { ProductType } from '~/types/product';

import GradientImg from './gradients.webp';
import styles from './styles.module.scss';

type Props = {
  newProduct: ProductType;
  onSubmit: () => void;
  onViewPlansClick?: () => void;
};

const ProactiveUser: FC<Props> = ({
  newProduct,
  onSubmit,
  onViewPlansClick,
}) => {
  const {
    name,
    new_price_ui,
    old_price_ui,
    regularity,
    regularity_ui,
    discount_percentage_ui,
    is_unlimited,
  } = newProduct;

  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);

  const handleSubmit = (): void => {
    analytics.trackEvent('subscription - click sub promo screen');
    onSubmit();
  };

  const bullets = [
    {
      text: `${discount_percentage_ui}% Off Monthly Payment`,
      icon: 'material-symbols:savings-outline',
    },
    {
      text: 'Unlimited Access',
      icon: 'material-symbols:all-inclusive',
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.left_side}>
        <Typography
          className={styles.title}
          variant={Typographies.TITLE_LARGE}
          component="h4"
        >
          Upgrade to Unlimited Annual Plan and save{' '}
          {newProduct.discount_percentage_ui}%!
        </Typography>

        <div className={styles.benefits}>
          {bullets.map((item) => (
            <Typography
              key={item.text}
              variant={Typographies.TITLE_MEDIUM}
              className={styles.benefits__item}
              component="p"
            >
              <Icon icon={item.icon} className={styles.benefits__icon} />
              <span>{item.text}</span>
            </Typography>
          ))}
        </div>

        {onViewPlansClick && isDesktop && (
          <div className={styles.view_plans_link}>
            <ViewAllPlansLink onClick={onViewPlansClick} />
          </div>
        )}
      </div>

      <div className={styles.right_side}>
        <div
          className={styles.product__card}
          style={{ backgroundImage: `url(${GradientImg})` }}
        >
          <Typography
            variant={Typographies.TITLE_SMALL}
            component="p"
            className={styles.product__discount}
          >
            - {discount_percentage_ui}%
          </Typography>

          <Typography
            variant={Typographies.TITLE_MEDIUM}
            component="h4"
            className={styles.product__title}
          >
            {name}
          </Typography>

          {!!old_price_ui && (
            <Typography
              variant={Typographies.TITLE_SMALL}
              component="p"
              className={styles.price__old}
            >
              ${old_price_ui / 100}
            </Typography>
          )}

          <Typography
            variant={Typographies.TITLE_LARGE}
            component="p"
            className={styles.price__current}
          >
            ${new_price_ui / 100}
          </Typography>

          <Typography
            variant={Typographies.TITLE_SMALL}
            component="p"
            className={styles.price__regularity}
          >
            Per {regularity_ui || regularity}
          </Typography>

          <Button
            variant="contained"
            color="neutral"
            size="large"
            fullWidth
            onClick={handleSubmit}
            className={styles.product__button}
          >
            Upgrade {is_unlimited ? 'to Unlimited' : 'Plan'}{' '}
          </Button>
        </div>

        {onViewPlansClick && !isDesktop && (
          <div className={styles.view_plans_link}>
            <ViewAllPlansLink onClick={onViewPlansClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProactiveUser;
