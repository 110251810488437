export enum PWADevice {
  Ios_Safari = 'ios_safari',
  Ios_Chrome = 'ios_chrome',
  Android_Chrome = 'android_chrome',
  Mac_Chrome = 'mac_chrome',
  Mac_Safari = 'mac_safari',
  Mac_Edge = 'mac_edge',
  Windows_Chrome = 'windows_chrome',
  Windows_Edge = 'windows_edge',
}
