import React, { FC } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';

import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';
import { ProductType } from '~/types/product';

import { ReactComponent as TickIcon } from './assets/tick.svg';
import styles from './styles.module.scss';

type Props = {
  product: ProductType;
  onProductClick?: (p: ProductType) => void;
  isDisabled?: boolean;
  isAccent?: boolean;
  isSelectable?: boolean;
  isActive?: boolean;
  orientation?: 'horizontal' | 'vertical';
};

const ProductCard: FC<Props> = ({
  product,
  onProductClick,
  isDisabled,
  isAccent,
  isSelectable,
  isActive,
  orientation = 'horizontal',
}) => {
  const {
    name,
    new_price_ui,
    old_price_ui,
    regularity,
    regularity_ui,
    discount_percentage_ui,
  } = product;

  const handleProductClick = (p: ProductType) => (): void => {
    analytics.trackEvent('subscription - click sub option', {
      product_name: p.name,
    });
    onProductClick && onProductClick(p);
  };

  return (
    <div
      className={clsx(styles.card, styles[orientation], {
        [styles.is_active]: isActive,
        [styles.is_disabled]: isDisabled,
        [styles.is_accent]: isAccent,
      })}
      onClick={handleProductClick(product)}
    >
      {!!discount_percentage_ui && (
        <div className={styles.discount}>Save {discount_percentage_ui}%</div>
      )}

      {isSelectable && (
        <div className={styles.checkbox}>
          <TickIcon className={styles.checkbox__icon} />
        </div>
      )}

      <Typography
        variant={Typographies.TITLE_MEDIUM}
        component="h4"
        className={styles.title}
      >
        {name}
      </Typography>

      <div className={styles.price}>
        {!!old_price_ui && (
          <Typography
            variant={Typographies.LABEL_MEDIUM}
            component="p"
            className={styles.price__old}
          >
            ${old_price_ui / 100}
          </Typography>
        )}

        <Typography
          variant={Typographies.TITLE_LARGE}
          component="p"
          className={styles.price__current}
        >
          ${new_price_ui / 100}
        </Typography>

        <Typography
          variant={Typographies.BODY_SMALL}
          component="p"
          className={styles.price__regularity}
        >
          Per {regularity_ui || regularity}
        </Typography>
      </div>
    </div>
  );
};

export default ProductCard;
