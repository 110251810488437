import * as yup from 'yup';

export const formSchema = yup
  .object()
  .shape({
    phrase: yup
      .string()
      .trim()
      .required('Phrase is required')
      .test('test-phrase', 'Confirmation phrase is incorrect', (value) => {
        if (value) return value === 'Delete My Account';
        return true;
      }),

    password: yup.string().trim().required('Password is required'),
  })
  .required();
