import { useNavigate } from 'react-router-dom';

const useQueryParams = (): {
  setQueryParams: (params: Record<string, number | string | null>) => void;
  getQueryParams: () => Record<string, number | string | null>;
  removeQueryParam: (paramKey: string) => void;
  updateQueryParams: (
    params: Record<string, number | string | null>,
    isShallow?: boolean,
  ) => void;
} => {
  const navigate = useNavigate();

  return {
    setQueryParams: (params, isShallow?: boolean): void => {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([, value]) => value),
      ) as Record<string, string>;

      const search = new URLSearchParams(filteredParams).toString();

      if (isShallow) {
        window.history.pushState(
          '',
          '',
          search
            ? `${window.location.pathname}?${search}`
            : window.location.pathname,
        );
      } else {
        navigate({ search }, { replace: true });
      }
    },
    getQueryParams: (): Record<string, number | string | null> => {
      return Object.fromEntries(new URLSearchParams(location.search));
    },
    removeQueryParam: (paramKey: string, isShallow?: boolean): void => {
      const currentParams = new URLSearchParams(location.search);
      currentParams.delete(paramKey);

      const search = currentParams.toString();

      if (isShallow) {
        window.history.pushState(
          '',
          '',
          search
            ? `${window.location.pathname}?${search}`
            : window.location.pathname,
        );
      } else {
        navigate({ search }, { replace: true });
      }
    },
    updateQueryParams: (
      params: Record<string, number | string | null>,
      isShallow?: boolean,
    ): void => {
      const currentParams = new URLSearchParams(location.search);

      Object.entries(params).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          const values = Array.isArray(value) ? value : [value];
          currentParams.set(key, values.map(String).join(','));
        } else {
          currentParams.delete(key);
        }
      });

      const search = currentParams.toString();
      if (isShallow) {
        window.history.pushState(
          '',
          '',
          search
            ? `${window.location.pathname}?${search}`
            : window.location.pathname,
        );
      } else {
        navigate({ search }, { replace: true });
      }
    },
  };
};

export default useQueryParams;
