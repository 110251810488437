import AnalyticManager, {
  AnalyticsItem,
} from '~/services/analytics/AnalyticManager';
import Amplitude from '~/services/analytics/vendors/Amplitude';
import MicrosoftClarity from '~/services/analytics/vendors/MicrosoftClarity';
import localStorage from '~/services/storage/localStorage';

const analyticsSubscribers: Array<AnalyticsItem> = [];

const AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY || null;

if (AMPLITUDE_API_KEY) {
  analyticsSubscribers.push(new Amplitude(AMPLITUDE_API_KEY));
}

analyticsSubscribers.push(new MicrosoftClarity());

const analyticsManager = new AnalyticManager(
  analyticsSubscribers,
  localStorage,
);

export default analyticsManager;
