export type ReasonType =
  | 'Support Experience'
  | 'Product Reliability'
  | 'Cost and Value'
  | 'Product Features and Functionality'
  | 'Usability and Design'
  | 'Other';

export enum FormFields {
  REASON = 'reason',
  SUB_REASON = 'sub_reason',
  RELATED_TO = 'related_to',
  FEEDBACK = 'feedback',
}

export type CancellationFormDataType = {
  [FormFields.REASON]: string;
  [FormFields.SUB_REASON]: string;
  [FormFields.RELATED_TO]: string;
  [FormFields.FEEDBACK]: string;
};
