import React, { FC, ReactNode } from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';

type Props = {
  isActive: boolean;
  isDisabled?: boolean;
  children: ReactNode;
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large';
  className?: string;
};

const ListOption: FC<Props> = ({
  isActive,
  isDisabled,
  onClick,
  size = 'medium',
  className,
  children,
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        styles.item,
        styles[size],
        className && styles[className],
        {
          [styles.is_active]: isActive,
          [styles.is_disabled]: isDisabled,
        },
      )}
    >
      {children}
    </div>
  );
};

export default ListOption;
