import React, { FC } from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';

interface Props {
  text: string;
  className?: string;
}

const InfoLabel: FC<Props> = ({ text, className }) => {
  return <span className={clsx(styles.label, className)}>{text}</span>;
};

export default InfoLabel;
