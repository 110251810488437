import errorLogger from '~/services/ErrorLogger';
import { PAYMENT_METHODS } from '~/types/payment';

const postParentMessage = (message: Record<string, any>): void => {
  window.parent.postMessage(message, '*');
};

const handlePaymentSuccess = (order: string): void => {
  const message = {
    order,
    method: PAYMENT_METHODS.PAYPAL,
    payment_status: 'success',
  };
  postParentMessage(message);
};

const handlePaymentError = (errorMessage: string): void => {
  const message = {
    error_message: errorMessage,
    method: PAYMENT_METHODS.PAYPAL,
    payment_status: 'error',
  };
  postParentMessage(message);
};

export const handleAlreadyProcessed = (): void => {
  const message = {
    error_message: 'Paypal order already processed',
    error_code: 'paypal_error',
  };
  postParentMessage(message);
};

export const handlePaypalPayment = (e: any): void => {
  const details = e?.detail?.data;

  if (!details) {
    handlePaymentError('Products failed');
    return;
  }

  const transactions = details.transactions;
  const order = details.order;
  const errorMessage = details.error?.recommended_message_for_user;

  if (
    !transactions ||
    !Array.isArray(transactions) ||
    transactions.length === 0
  ) {
    handlePaymentError('Products failed');
    return;
  }

  const successTransaction = transactions.find(
    (tr) => tr.type === 'pay' && tr.status === 'success',
  );

  if (!successTransaction) {
    handlePaymentError(errorMessage || 'Products failed');
    return;
  }

  handlePaymentSuccess(order);
};

export const renderPaypalButton = (url: string): void => {
  if (!url) {
    errorLogger.captureException(
      Error('Incorrect URL provided to renderPaypalButton'),
    );

    return;
  }

  const oldScript = document.head.querySelector(`#paypal_script_id`);

  if (oldScript) {
    oldScript.remove();
  }

  const paypalScript = document.createElement('script');

  paypalScript.src = url;
  paypalScript.id = 'paypal_script_id';
  paypalScript.setAttribute('data-height', '55');

  document.head.appendChild(paypalScript);
};
