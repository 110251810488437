import React, { FC } from 'react';

import { Typography } from '@mui/material';

import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const benefits = [
  {
    emoji: '✍️',
    text: 'Words Generated',
    value: '10,000+',
  },
  {
    emoji: '📍',
    text: 'Tasks Completed',
    value: '500+',
  },
  {
    emoji: '🕗',
    text: 'Hours Saved',
    value: '80+ ',
  },
];

const StaticUserBenefits: FC = () => {
  return (
    <div className={styles.benefits}>
      {benefits.map((ben) => (
        <Typography
          className={styles.benefit}
          variant={Typographies.BODY_LARGE}
          component="p"
          key={ben.value}
        >
          <span className={styles.benefit__emoji}>{ben.emoji}</span>
          <span className={styles.benefit__text}>{ben.text}</span>
          <span className={styles.benefit__value}>{ben.value}</span>
        </Typography>
      ))}
    </div>
  );
};

export default StaticUserBenefits;
