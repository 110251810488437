import React, { FC } from 'react';

import clsx from 'clsx';

import { AvatarType } from '~/components/molecules/LogoMessageWrapper';

import styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  logo_src?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  variant: AvatarType;
  className?: string;
};

const FallbackLetterAvatar: FC<Props> = ({
  children,
  logo_src,
  variant,
  onClick,
  className,
}) => {
  return (
    <i
      onClick={(e): void => {
        onClick && onClick(e);
      }}
      className={clsx(styles.icon, className, styles[variant])}
    >
      {logo_src ? (
        <img style={{ width: '100%' }} src={logo_src} alt="image" />
      ) : (
        children
      )}
    </i>
  );
};

export default FallbackLetterAvatar;
