import React, { FC, useEffect } from 'react';

import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import DoneImg from '~/assets/images/done.webp';
import DonePreviewImg from '~/assets/images/done_preview.png';
import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import { SubscriptionPath } from '~/modules/settings/constants';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const Success: FC = () => {
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleGoToSubscriptionPage = (): void => {
    navigate(SubscriptionPath);
  };

  useEffect(() => {
    if (!state) {
      handleGoToSubscriptionPage();
      return;
    }

    analytics.trackEvent('subscription - verify cancel sub');
  }, [state]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.image}>
          <Image src={DoneImg} preview={DonePreviewImg} />
        </div>

        <Typography
          variant={Typographies.HEADLINE_MEDIUM}
          component="h4"
          className={styles.title}
        >
          Thank your for using Justdone.ai!
        </Typography>

        <Typography
          variant={Typographies.TITLE_MEDIUM}
          component="p"
          className={styles.text}
        >
          You have successfully canceled your subscription.
          <br />
          <br />
          You can still enjoy the benefits of your account until the end of the
          billing period.
          <br />
          <br />
          We hope to see you again soon!
        </Typography>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={handleGoToSubscriptionPage}
        fullWidth={!isDesktop}
        sx={{ '&&': { marginTop: '40px' } }}
      >
        Go to Settings
      </Button>
    </div>
  );
};

export default Success;
