import { useEffect, useState } from 'react';

const useVisualViewportResize = (): boolean => {
  const [isResized, setIsResized] = useState(false);

  useEffect(() => {
    setIsResized(true);

    const timer = setTimeout(() => {
      setIsResized(false);
    }, 100);

    return (): void => {
      clearTimeout(timer);
    };
  }, [window.visualViewport?.height]);

  return isResized;
};

export default useVisualViewportResize;
