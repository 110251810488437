import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import MacSafariAddBtnImg from '~/features/pwa/assets/v4_mac_safari_add_button.webp';
import MacSafariAddBtnPreviewImg from '~/features/pwa/assets/v4_mac_safari_add_button_preview.png';
import MacSafariAddressImg from '~/features/pwa/assets/v4_mac_safari_address_bar.webp';
import MacSafariAddressPreviewImg from '~/features/pwa/assets/v4_mac_safari_address_bar_preview.png';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

type Props = {
  onSubmit: () => void;
};

const SafariDesktop: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation('modal');

  return (
    <div className={styles.container}>
      <div className={styles.steps}>
        <div className={styles.steps__item}>
          <Typography
            variant={Typographies.TITLE_MEDIUM}
            className={styles.steps__item_title}
            component="h4"
          >
            1.{' '}
            {t(
              'pwa_v4.safari_desktop_steps[0]',
              'Hit such icon in top right of your screen',
            )}
          </Typography>

          <div>
            <Image
              src={MacSafariAddressImg}
              preview={MacSafariAddressPreviewImg}
            />
          </div>
        </div>

        <div className={styles.steps__item}>
          <Typography
            variant={Typographies.TITLE_MEDIUM}
            className={styles.steps__item_title}
            component="h4"
          >
            2.
            <Trans i18nKey="pwa_v4.safari_desktop_steps[1]" t={t}>
              Hit <b>“Add to Dock”</b>
            </Trans>
          </Typography>

          <div>
            <Image
              src={MacSafariAddBtnImg}
              preview={MacSafariAddBtnPreviewImg}
            />
          </div>
        </div>
      </div>

      <Button
        color="primary"
        variant="text"
        onClick={onSubmit}
        className={styles.submit_button}
      >
        <Icon
          icon="ic:round-done"
          style={{ color: colors.primary_40 }}
          width="18"
        />
        <span>{t('general:done', 'Done')}</span>
      </Button>
    </div>
  );
};

export default SafariDesktop;
