export const fonts = {
  default: 'Inter, sans-serif',
  title: 'Inter Serif Pro, sans-serif',
};

export enum Typographies {
  DISPLAY_SMALL = 'display_small',
  DISPLAY_LARGE = 'display_large',
  DISPLAY_MEDIUM = 'display_medium',
  HEADLINE_SMALL = 'headline_small',
  HEADLINE_LARGE = 'headline_large',
  HEADLINE_MEDIUM = 'headline_medium',
  TITLE_SMALL = 'title_small',
  TITLE_LARGE = 'title_large',
  TITLE_MEDIUM = 'title_medium',
  LABEL_SMALL = 'label_small',
  LABEL_LARGE = 'label_large',
  LABEL_MEDIUM = 'label_medium',
  BODY_SMALL = 'body_small',
  BODY_LARGE = 'body_large',
  BODY_MEDIUM = 'body_medium',
}

export const typography = {
  [Typographies.DISPLAY_SMALL]: {
    fontSize: 36,
    fontWeight: 600,
    lineHeight: '44px',
    letterSpacing: '0',
  },
  [Typographies.DISPLAY_MEDIUM]: {
    fontSize: 45,
    fontWeight: 600,
    lineHeight: '52px',
    letterSpacing: '0',
  },
  [Typographies.DISPLAY_LARGE]: {
    fontSize: 57,
    fontWeight: 600,
    lineHeight: '64px',
    letterSpacing: '-0.25px',
  },
  [Typographies.HEADLINE_SMALL]: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0',
  },
  [Typographies.HEADLINE_MEDIUM]: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: '36px',
    letterSpacing: '0',
  },
  [Typographies.HEADLINE_LARGE]: {
    fontSize: 32,
    fontWeight: 500,
    lineHeight: '40px',
    letterSpacing: '0',
  },
  [Typographies.TITLE_SMALL]: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  [Typographies.TITLE_MEDIUM]: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  [Typographies.TITLE_LARGE]: {
    fontSize: 22,
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '0',
  },
  [Typographies.LABEL_SMALL]: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.5px',
  },
  [Typographies.LABEL_MEDIUM]: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.5px',
  },
  [Typographies.LABEL_LARGE]: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  [Typographies.BODY_SMALL]: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  [Typographies.BODY_MEDIUM]: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  [Typographies.BODY_LARGE]: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '23px',
    letterSpacing: '0.5px',
  },
};
