class QueryParams {
  static getQueryParam(name: string): string | null {
    const queryString = window.location.search;

    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get(name);
    }
    return null;
  }
}

export default QueryParams;
