import React, { FC } from 'react';

import { Icon } from '@iconify/react';

import { Device, getDeviceName } from '~/utils/userAgentInfo';

const CHROME_EXTENSION_ID = 'hmccfhejfgfjkmmmmhmlnhkoidinlaab';

const CHROME_EXTENSION_URL = `https://chromewebstore.google.com/detail/justdone-ai-chrome-extens/${CHROME_EXTENSION_ID}`;

export const FIREFOX_EXTENSION_URL =
  'https://addons.mozilla.org/en-US/firefox/addon/justdone-ai-writing-assistant/';

export enum EXTENSION {
  CHROME = 'chrome',
  FIREFOX = 'firefox',
}

export const ExtensionConfig: Record<
  EXTENSION,
  {
    devices: Device[];
    longTitle?: string;
    title: string;
    extensionUrl: string;
    Icon: FC<{ className?: string }>;
  }
> = {
  [EXTENSION.CHROME]: {
    devices: [Device.Linux_Chrome, Device.Windows_Chrome, Device.Mac_Chrome],
    longTitle: 'Google Chrome',
    title: 'Chrome',
    extensionUrl: CHROME_EXTENSION_URL,
    Icon: ({ className }) => <Icon icon="logos:chrome" className={className} />,
  },
  [EXTENSION.FIREFOX]: {
    devices: [Device.Linux_Firefox, Device.Windows_Firefox, Device.Mac_Firefox],
    title: 'Firefox',
    extensionUrl: FIREFOX_EXTENSION_URL,
    Icon: ({ className }) => (
      <Icon icon="logos:firefox" className={className} />
    ),
  },
};

class Extension {
  constructor(readonly extension: EXTENSION | null) {
    this.extension = extension;
  }

  getExtensionUrl(): string {
    return this.extension
      ? ExtensionConfig[this.extension].extensionUrl
      : ExtensionConfig[EXTENSION.CHROME].extensionUrl;
  }

  getTitle(): string {
    return this.extension
      ? ExtensionConfig[this.extension].title
      : ExtensionConfig[EXTENSION.CHROME].title;
  }

  getLongTitle(): string {
    return this.extension
      ? ExtensionConfig[this.extension].longTitle ||
          ExtensionConfig[this.extension].title
      : ExtensionConfig[EXTENSION.CHROME].longTitle ||
          ExtensionConfig[EXTENSION.CHROME].title;
  }

  getIcon(): FC<{ className?: string }> {
    return this.extension
      ? ExtensionConfig[this.extension].Icon
      : ExtensionConfig[EXTENSION.CHROME].Icon;
  }

  checkIsExtensionAvailable(): boolean {
    return Boolean(this.extension && ExtensionConfig[this.extension]);
  }

  sendAuthTokens(accessToken: string, refreshToken: string): void {
    try {
      if (EXTENSION.CHROME === this.extension) {
        chrome?.runtime?.sendMessage(CHROME_EXTENSION_ID, {
          key: 'auth',
          data: { access_token: accessToken, refresh_token: refreshToken },
          function(response) {
            if (!response.success) {
              // @ts-ignore
              console.error('error', response);
            }
          },
        });
      } else {
        const signInEvent = new CustomEvent('signIn', {
          detail: {
            access_token: accessToken,
            refresh_token: refreshToken,
          },
        });
        document.dispatchEvent(signInEvent);
        window.setTimeout(() => {
          document.dispatchEvent(signInEvent);
        }, 1000);
        window.setTimeout(() => {
          document.dispatchEvent(signInEvent);
        }, 2500);
      }

      // fallback for firefox
    } catch (e: any) {
      console.error(e);
    }
  }
}

const device = getDeviceName();

const extension: EXTENSION | null =
  (Object.entries(ExtensionConfig).find(([, { devices }]) =>
    devices.includes(device),
  )?.[0] as EXTENSION) || null;

export default new Extension(extension);
