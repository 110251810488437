import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import HomeScreenImg from '~/features/pwa/assets/v4_windows_home_screen_modal.webp';
import HomeScreenPreviewImg from '~/features/pwa/assets/v4_windows_home_screen_modal_preview.jpg';
import { ReactComponent as WindowsIcon } from '~/features/pwa/assets/windows_icon.svg';
import i18next from '~/i18n/config';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';

import commonStyles from '../commonStyles.module.scss';

const { t } = i18next;

const bullets = [
  t('modal:pwa_v4.bullets[0]', '10 seconds installation'),
  t('modal:pwa_v4.bullets[1]', 'No authorization'),
  t('modal:pwa_v4.bullets[2]', 'No annoying notifications'),
];

const platform = 'Windows';

type Props = {
  onSubmit: () => void;
  onDismiss: () => void;
};

const Windows: FC<Props> = ({ onSubmit, onDismiss }) => {
  const { t } = useTranslation('modal');

  return (
    <div className={commonStyles.windows_mac_container}>
      <div className={commonStyles.image}>
        <Image src={HomeScreenImg} preview={HomeScreenPreviewImg} />
      </div>

      <div className={commonStyles.box}>
        <Typography
          variant={Typographies.HEADLINE_SMALL}
          className={commonStyles.title}
          component="h3"
        >
          <Trans i18nKey="pwa_v4.titles[0]" t={t}>
            Work Faster with Justdone App for {{ platform }}
          </Trans>
        </Typography>

        <div className={commonStyles.bullets}>
          {bullets.map((el) => (
            <div key={el} className={commonStyles.bullets__item}>
              <Icon
                icon="ic:round-done"
                style={{ color: colors.primary_40 }}
                width="18"
              />

              <Typography
                variant={Typographies.BODY_LARGE}
                className={commonStyles.bullets__item_text}
                component="p"
              >
                {el}
              </Typography>
            </div>
          ))}
        </div>

        <div className={commonStyles.buttons_container}>
          <Button
            className={commonStyles.submit_button}
            color="primary"
            variant="contained"
            onClick={onSubmit}
          >
            <WindowsIcon />
            <span>{t('general:install', 'Install')}</span>
          </Button>

          <Button color="primary" variant="text" onClick={onDismiss}>
            {t('general:i_will_do_it_later', 'I will do it later')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Windows;
