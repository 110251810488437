import React, { FC, useMemo } from 'react';

import { Icon } from '@iconify/react';
import clsx from 'clsx';

import BlackTooltip from '~/components/atoms/BlackTooltip';
import Button from '~/components/atoms/buttons/Button';
import { useModalContext } from '~/contexts/modal/ModalContext';
import useErrors from '~/hooks/useErrors';
import ChooseSubscriptionModal, {
  ReasonType,
} from '~/modules/settings/components/modals/ChooseSubscriptionModal';
import { useRestoreSubscription } from '~/modules/settings/hooks/useRestoreSubscription';
import analytics from '~/services/analytics';
import { AnalyticsEventNames } from '~/services/analytics/types';
import extension from '~/services/Extension';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { colors } from '~/theme/colors';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

import styles from './styles.module.scss';

enum BUTTON_SUGGESTION {
  RESTORE = 'restore',
  UPGRADE = 'upgrade',
  EXTENSION = 'extension',
  REDEMPTION = 'redemption',
  REDEMPTION_PENDING = 'redemption_pending',
  REDEMPTION_FAILED = 'redemption_failed',
}

const extensionTitle = extension.getTitle();
const ExtensionIcon = extension.getIcon();
const extensionUrl = extension.getExtensionUrl();

const getButtonSuggestion = (
  subStatus,
  billingPeriod,
  isExtensionInstalled,
  isStripeUser,
): BUTTON_SUGGESTION | null => {
  if (
    !isStripeUser &&
    [
      SUBSCRIPTION_STATUS.CANCEL_PENDING,
      SUBSCRIPTION_STATUS.CANCELLED,
    ].includes(subStatus)
  ) {
    return BUTTON_SUGGESTION.RESTORE;
  }

  if (!isStripeUser && subStatus === SUBSCRIPTION_STATUS.REDEMPTION) {
    return BUTTON_SUGGESTION.REDEMPTION;
  }

  if (
    !isStripeUser &&
    subStatus === SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_PENDING
  ) {
    return BUTTON_SUGGESTION.REDEMPTION_PENDING;
  }

  if (
    !isStripeUser &&
    subStatus === SUBSCRIPTION_STATUS.REDEMPTION_PAYMENT_FAILED
  ) {
    return BUTTON_SUGGESTION.REDEMPTION_FAILED;
  }

  if (!isStripeUser && billingPeriod !== 365) {
    return BUTTON_SUGGESTION.UPGRADE;
  }

  if (!isExtensionInstalled) {
    return BUTTON_SUGGESTION.EXTENSION;
  }

  return null;
};

const ButtonSuggestion: FC = () => {
  const {
    user_subscription,
    user_product,
    is_chrome_extension_installed,
    is_stripe_user,
  } = useAppSelector((state) => state.profile);

  const { reportUserErrors } = useErrors();
  const { handleOpenModal } = useModalContext();
  const { restoreSubscription } = useRestoreSubscription();

  const handleClickExtension = (): void => {
    analytics.trackEvent('subscription - extension promo button click');
    window.open(extensionUrl, '_blank', 'noopener,noreferrer');
  };

  const handleRestoreSubscription = async (): Promise<void> => {
    analytics.trackEvent('subscription - restore sub', {
      sub: user_subscription.subscription_name,
    });

    if (user_subscription.status === SUBSCRIPTION_STATUS.CANCEL_PENDING) {
      try {
        await restoreSubscription();
      } catch (e) {
        reportUserErrors({
          error: e,
          method: 'restoreSubscription',
          userMessage:
            'Unable to restore subscription. Please contact support or',
        });
      }
      return;
    }

    handleOpenModal({
      onClose: () => {
        analytics.trackEvent('subscription - close not active sub screen', {
          sub: user_subscription.subscription_name,
        });
      },
      maxWidth: '460px',
      component: ({ onClose }) => (
        <ChooseSubscriptionModal
          onClose={(): void => {
            onClose();
          }}
          type={ReasonType.NO_ACTIVE_SUBSCRIPTION}
        />
      ),
    });

    analytics.trackEvent('subscription - not active sub screen', {
      sub: user_subscription.subscription_name,
    });
  };

  const handleRestoreSubscriptionHard = async (): Promise<void> => {
    analytics.trackEvent('subscription - redemption issue click', {
      sub: user_subscription.subscription_name,
      place: 'header',
    });
    handleOpenModal({
      hideCloseButton: true,
      disableBackdropClick: true,

      component: ({ onClose }) => (
        <ChooseSubscriptionModal
          onClose={onClose}
          type={ReasonType.REDEMPTION}
        />
      ),
    });
  };

  const handleRedemptionFailed = async (): Promise<void> => {
    analytics.trackEvent('subscription - failed redemption issue click', {
      sub: user_subscription.subscription_name,
      place: 'header',
    });

    handleOpenModal({
      hideCloseButton: true,
      disableBackdropClick: true,

      component: ({ onClose }) => (
        <ChooseSubscriptionModal
          onClose={onClose}
          type={ReasonType.REDEMPTION_FAILED}
        />
      ),
    });
  };

  const handleUpgradeSubscription = (): void => {
    analytics.trackEvent('subscription - sub promo button click');
    analytics.trackEvent(
      `subscription - sub promo screen` as AnalyticsEventNames,
      {
        opening_method: 'manual',
      },
    );

    handleOpenModal({
      maxWidth: '700px',
      closeButtonColor: colors.black,
      onClose: () => {
        analytics.trackEvent(
          `subscription - close sub promo screen` as AnalyticsEventNames,
          {
            opening_method: 'manual',
          },
        );
      },

      component: ({ onClose }) => (
        <ChooseSubscriptionModal
          onClose={onClose}
          type={ReasonType.PROACTIVE_USER}
        />
      ),
    });
  };

  const SuggestionButton = useMemo(() => {
    const buttonSuggestion = getButtonSuggestion(
      user_subscription.status,
      user_product.billing_period,
      is_chrome_extension_installed,
      is_stripe_user,
    );

    if (!buttonSuggestion) {
      return <></>;
    }

    switch (buttonSuggestion) {
      case BUTTON_SUGGESTION.RESTORE:
        return (
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            startIcon={<Icon icon="material-symbols:magic-button-outline" />}
            onClick={handleRestoreSubscription}
            className={clsx(styles.button, styles['button--restore'])}
          >
            Restore
          </Button>
        );

      case BUTTON_SUGGESTION.REDEMPTION:
        return (
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            startIcon={<Icon icon="material-symbols:error-outline" />}
            onClick={handleRestoreSubscriptionHard}
            className={clsx(styles.button, styles['button--redemption'])}
          >
            Payment Issues
          </Button>
        );

      case BUTTON_SUGGESTION.REDEMPTION_PENDING:
        return (
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            startIcon={<Icon icon="line-md:loading-loop" width="24" />}
            className={clsx(
              styles.button,
              styles['button--redemption-pending'],
            )}
          >
            Fixing payment issue...
          </Button>
        );

      case BUTTON_SUGGESTION.REDEMPTION_FAILED:
        return (
          <BlackTooltip
            placement="bottom"
            title="Your current payment method is not working. Update it to continue enjoying features of Justdone"
          >
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              startIcon={<Icon icon="material-symbols:error-outline" />}
              onClick={handleRedemptionFailed}
              className={clsx(styles.button, styles['button--redemption'])}
            >
              Update Payment Method
            </Button>
          </BlackTooltip>
        );

      case BUTTON_SUGGESTION.UPGRADE:
        return (
          <Button
            fullWidth
            startIcon={
              <Icon icon="material-symbols:bolt-outline" color="#FFE86C" />
            }
            onClick={handleUpgradeSubscription}
            className={clsx(styles.button, styles['button--upgrade'])}
          >
            Upgrade Now
          </Button>
        );

      case BUTTON_SUGGESTION.EXTENSION:
        return (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<ExtensionIcon />}
            onClick={handleClickExtension}
            className={clsx(styles.button, styles['button--extension'])}
          >
            Add {extensionTitle} Extension
          </Button>
        );

      default:
        return <></>;
    }
  }, [
    user_subscription.status,
    user_product.billing_period,
    is_chrome_extension_installed,
  ]);

  return <>{SuggestionButton}</>;
};

export default ButtonSuggestion;
